import { customRequest, handleResponse } from "src/helpers";
import { buildQueryString } from "src/utils/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const reportService = {
  profitLossExport,
  profitLossJson,
  timeclocksExport,
  timeclocksJson,
  activeClientsExport,
  activeClientsJson,
};

function profitLossExport(params = {}) {
  const requestOptions = {
    ...customRequest("POST"),
  };

  const queryString = buildQueryString(params).toString();
  const url = `${config.apiUrl}/Reports/sheng/profitandloss/export?${queryString}`;

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        Promise.reject("error");
      }
    })
    .then((response) => {
      if (response) {
        let dataCsv = response;
        let data = "data:text/csv;charset=utf-8,";
        data += encodeURIComponent(dataCsv);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", "profit-and-loss.csv");
        link.click();
      } else {
        throw new Error("Error");
      }
    });
}

function profitLossJson(params = {}) {
  const requestOptions = {
    ...customRequest("POST"),
  };

  const queryString = buildQueryString(params).toString();
  const url = `${config.apiUrl}/Reports/sheng/profitandloss/json?${queryString}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function timeclocksExport(params = {}) {
  const requestOptions = {
    ...customRequest("POST"),
  };

  let { clients, users } = params;
  delete params.clients;
  delete params.users;
  let queryString = buildQueryString(params);
  if (clients && clients.length > 0) {
    clients.map((clientId) => {
      queryString += `&clients=${clientId}`;
    });
  }
  if (users && users.length > 0) {
    users.map((userId) => {
      queryString += `&users=${userId}`;
    });
  }
  const url = `${config.apiUrl}/Reports/timeclock/export?${queryString}`;

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        Promise.reject("error");
      }
    })
    .then((response) => {
      if (response) {
        let dataCsv = response;
        let data = "data:text/csv;charset=utf-8,";
        data += encodeURIComponent(dataCsv);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", "timeclocks.csv");
        link.click();
      } else {
        throw new Error("Error");
      }
    });
}

function timeclocksJson(params = {}) {
  const requestOptions = {
    ...customRequest("POST"),
  };

  let { clients, users } = params;
  delete params.clients;
  delete params.users;
  let queryString = buildQueryString(params);
  if (clients && clients.length > 0) {
    clients.map((clientId) => {
      queryString += `&clients=${clientId}`;
    });
  }
  if (users && users.length > 0) {
    users.map((userId) => {
      queryString += `&users=${userId}`;
    });
  }
  const url = `${config.apiUrl}/Reports/timeclock/json?${queryString}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function activeClientsExport(params = {}) {
  const requestOptions = {
    ...customRequest("POST"),
  };

  const queryString = buildQueryString(params).toString();
  const url = `${config.apiUrl}/Reports/activeclients/export?${queryString}`;

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        Promise.reject("error");
      }
    })
    .then((response) => {
      if (response) {
        let dataCsv = response;
        let data = "data:text/csv;charset=utf-8,";
        data += encodeURIComponent(dataCsv);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", "active-clients.csv");
        link.click();
      } else {
        throw new Error("Error");
      }
    });
}

function activeClientsJson(params = {}) {
  const requestOptions = {
    ...customRequest("POST"),
  };

  const queryString = buildQueryString(params).toString();
  const url = `${config.apiUrl}/Reports/activeclients/json?${queryString}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}