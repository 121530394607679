import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import sidebar from "./modules/sidebar";
import header from "./modules/header";
import footer from "./modules/footer";
import translation from "./modules/translation";
import theme from "./modules/theme";

// import { auth } from './auth.module';

import { alert } from './alert.module';
import { snackbar } from "./snackbar.module";
import { account } from "./account.module";
import { accounts } from "./accounts.module";
import { userGroup } from "./userGroup.module";
import { invoice } from "./invoice.module";
import { quote } from "./quote.module";
import { invoiceItem } from "./invoiceItem.module";
import { invoiceNotificationRule } from "./invoiceNotificationRule.module";
import { invoiceEmailTemplates } from "./invoiceEmailTemplates.module";
import { item } from "./item.module";
import { client } from "./client.module";
import { user } from "./users.module";
import { task } from "./task.module";
import { paymentHistory } from "./paymentHistory.module";
import { timeclock } from "./timeclock.module";
import { timeclockEntry } from "./timeclockEntry.module";
import { company } from "./company.module";
import { subscription } from "./subscription.module";
import { category } from "./category.module";
import { notes } from "./notes.module";
import { itemPurchase } from "./itemPurchase.module";
import { vault } from "./vault.module";
import { statement } from "./statement.module";
import { vaultFolder } from "./vaultFolder.module";
import { storage } from "./storage.module";
import { storageFolder } from "./storageFolder.module";
import { file } from "./file.module";
import { timeclockImport } from "./timeclockImport.module";
import { pay } from "./pay.module";
import { auditLog } from "./auditLog.module";
import { contact } from "./contact.module";
import { expense } from "./expense.module";
import { vendor } from "./vendor.module";
import { report } from "./report.module";
import { gginvoice } from "./gginvoice.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters,
  mutations,
  actions,
  modules: {
    navigations: {
      namespaced: true,
      modules: {
        header,
        sidebar,
        footer,
      },
    },
    scheme: {
      namespaced: true,
      modules: {
        theme,
      },
    },
    translation,
    alert,
    account,
    accounts,
    snackbar,
    userGroup,
    quote,
    invoice,
    invoiceItem,
    invoiceNotificationRule,
    invoiceEmailTemplates,
    gginvoice,
    client,
    user,
    item,
    task,
    timeclock,
    timeclockEntry,
    company,
    subscription,
    category,
    paymentHistory,
    notes,
    itemPurchase,
    vault,
    statement,
    vaultFolder,
    storage,
    storageFolder,
    file,
    timeclockImport,
    pay,
    auditLog,
    contact,
    expense,
    vendor,
    report,
  },
});
