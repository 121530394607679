import moment from "moment-timezone";
import _ from "lodash";

// const TIMEZONE = "America/New_York";
// const TIMEZONE = "Asia/Jakarta";
const TIMEZONE = undefined;

export function isStrongPassword(value) {
  const containsUppercase = /[A-Z]/.test(value);
  const containsLowercase = /[a-z]/.test(value);
  const containsNumber = /[0-9]/.test(value);
  const containsSpecial = /[#?!@$%^&*-]/.test(value);
  const isValid =
    containsUppercase && containsLowercase && containsNumber && containsSpecial;
  return isValid;
}

export function isAlphanumeric(str) {
  var code, i, len;

  if (typeof str !== "string") {
    return false;
  }

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

export function camel(str) {
  const camel = (str || "").replace(/-([^-])/g, (g) => g[1].toUpperCase());

  return capitalize(camel);
}

export function camelActual(str) {
  return (str || "").replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ""));
}

export function kebab(str) {
  return (str || "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

export function capitalize(str) {
  str = str || "";

  return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`;
}

export function getLongId(id) {
  // btoa() but for node
  return Buffer.from(`gid://shopify/Product/${id}`, "binary").toString(
    "base64"
  );
}

export function findProduct(store, id) {
  return store.state.store.products.find((p) => p.id === id);
}

export function isOnSale(variants) {
  return variants.some((variant) => {
    return parseFloat(variant.price) < parseFloat(variant.compareAtPrice);
  });
}

export function randomNumber(min, max) {
  return Math.floor(Math.random() * max) + min;
}

export function randomString(length = 5) {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}
export function getMathRandom(length) {
  return Math.floor(
    Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
  );
}
// Must be called in Vue context
export function goTo(id) {
  this.$vuetify.goTo(id).then(() => {
    if (!id) {
      return (document.location.hash = "");
    }

    if (history.replaceState) {
      history.replaceState(null, null, id);
    } else {
      document.location.hash = id;
    }
  });
}

export function formatPrice(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    // minimumFractionDigits: 0,
  });
  return formatter.format(value);
}

export function formatDate(value) {
  // return value
  //   ? momentLocal(value).format(process.env.VUE_APP_DATE_FORMAT_DISPLAY)
  //   : " - ";
  return value ? moment(value).format("YYYY-MM-DD") : " - ";
}

export function formatDatetime(value) {
  return value
    ? momentLocal(value).format(process.env.VUE_APP_DATETIME_FORMAT)
    : " - ";
}

export function momentLocal(value) {
  let tz = TIMEZONE ? TIMEZONE : moment.tz.guess();
  return moment.utc(value).tz(tz);
}

export function localToUtc(value) {
  let tz = TIMEZONE ? TIMEZONE : moment.tz.guess();
  return moment.tz(value, process.env.VUE_APP_DATETIME_FORMAT, tz).utc();
}

export function formatFileSize(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function zeroPad(num) {
  return ("0" + num).slice(-2);
}

export function roundUp(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
  // return Math.round(num * 100) / 100;
}

export function roundPrecise(num, precision) {
  return _.round(num, precision);
}

export function formatTimeRoundupSecond(secs) {
  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  var days = Math.floor(hours / 24);
  hours = hours % 24;
  if (secs > 0) {
    minutes++;
  }

  if (days > 0) {
    return `${days}d ${zeroPad(hours)}h ${zeroPad(minutes)}m`;
  }
  return `${zeroPad(hours)}h ${zeroPad(minutes)}m`;
}

export function formatTime(secs) {
  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  var days = Math.floor(hours / 24);
  hours = hours % 24;
  if (days > 0) {
    return `${days}d ${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(secs)}`;
  }
  return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(secs)}`;
}

export function sumTimeClockEntries(timeClockEntries) {
  if (timeClockEntries.length <= 0) {
    return 0;
  }
  let totalMs = 0;
  timeClockEntries.forEach((entry) => {
    if (entry.stopTime && entry.startTime) {
      let now = moment(entry.startTime).utc(true);
      var then = moment(entry.stopTime).utc(true);
      var ms = Math.abs(now.diff(then, "second"));
      var minutes = Math.floor(ms / 60);
      ms = ms % 60;
      if (ms > 0) {
        minutes++;
      }
      totalMs += minutes * 60;
    }
  });
  return parseInt(totalMs);
}

export function removeStorage(name) {
  try {
    localStorage.removeItem(name);
    localStorage.removeItem(name + "_expiresIn");
  } catch (e) {
    return false;
  }
  return true;
}

export function getStorage(key) {
  var now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  var expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      var value = localStorage.getItem(key);
      return JSON.parse(value);
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
      );
      return null;
    }
  }
}

export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  } else {
    expires = Math.abs(expires); //make sure it's positive
  }

  var now = Date.now(); //millisecs since epoch time, lets deal only with integer
  var schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, JSON.stringify(value));
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    return false;
  }
  return true;
}

export function buildQueryString(params = {}) {
  let filteredParam = {};
  Object.entries(params).map((item) => {
    if (item[1] !== null) {
      filteredParam[item[0]] = item[1];
    }
  });

  let queryString = new URLSearchParams(filteredParam);
  return queryString;
}

export async function readDataURLFromBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  })
}