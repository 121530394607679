import { invoiceService } from "src/services/api";

const defaultFilter = {
  clientId: null,
  dateStart: null,
  dateEnd: null,
};

const state = {
  status: {},
  errors: [],
  statusText: "",
  data: [],
  summary: {
    totalBalance: 0,
    totalInvoice: 0,
    totalPaid: 0,
  },
  loadingTable: false,
  tableFilter: {
    ...defaultFilter,
  },
};

const actions = {
  async getStatementAll({ commit, dispatch }, queryParam = {}) {
    await invoiceService.getStatementAll(queryParam).then(
      (response) => {
        commit("setData", response.data);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async getStatementSummary({ commit, dispatch }, queryParam = {}) {
    await invoiceService.getStatementSummary(queryParam).then(
      (response) => {
        commit("setSummary", response.data);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setTableFilter(state, data) {
    state.tableFilter = data;
  },

  setMetaData(state, data) {
    state.metaData = data;
  },

  setSingle(state, data) {
    state.single = data;
  },

  setSummary(state, data) {
    state.summary = data;
  },

  clearFilterField(state) {
    state.dataFilter = [];
  },

  setFilterField(state, param) {
    state.dataFilter = param;
  },

  setFilter(state, param) {
    state.dataParam = param;
  },

  setNextNumber(state, data) {
    state.nextNumber = data;
  },

  setData(state, data) {
    state.data = data;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  statusOk(state) {
    state.statusText = "ok";
    state.status = { error: false, message: "ok" };
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = { error: false, message: response.message };
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getTableFilter(state) {
    return state.tableFilter;
  },
  getAll(state) {
    let data = state.data;
    return data;
  },
  getMetaData(state) {
    let data = state.metaData;
    return data;
  },
};

export const statement = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
