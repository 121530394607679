import { authHeader } from "../../helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const invoiceItemService = {
  getAll,
  getById,
  update,
  create,
  delete: _delete,
};

function getAll(params = {}) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  params.PageSize = 500;
  let queryString = new URLSearchParams(params);
  return fetch(`${config.apiUrl}/InvoiceLines?${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return { data: response };
    });
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/InvoiceLines/${id}`, requestOptions).then(
    handleResponse
  );
}

function create(payload) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  return fetch(`${config.apiUrl}/Clients`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/Clients/${user.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(payload) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/Clients/${payload.id}`, requestOptions).then(
    handleResponse
  );
}

/**
 * 
 * @param {
 * errors: Object
 status: 400
 title: "One or more validation errors occurred."
 traceId: "00-d3528f73c9980c49b4621060e929da8e-06b87f3805898e4a-00"
 type:} response 
 */
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        // location.reload(true);
      }

      // const error = (data && data.message) || response.statusText;
      return Promise.reject(data);
    }

    return data;
  });
}
