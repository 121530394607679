import { customRequest, handleResponse } from "src/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL + "/VaultFolders",
};

export const vaultFolderService = {
  getAll,
  getById,
  update,
  create,
  delete: _delete,
};

function getAll(params = {}) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  params.PageSize = 1000; // since category doesnt have pagination in frontend
  let queryString = new URLSearchParams(params);
  return fetch(`${config.apiUrl}?${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getById(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/${id}`, requestOptions).then(handleResponse);
}

function create(payload) {
  const requestOptions = {
    ...customRequest("POST", payload),
  };

  return fetch(`${config.apiUrl}`, requestOptions).then(handleResponse);
}

function update(payload) {
  const requestOptions = {
    ...customRequest("PUT", payload),
  };

  return fetch(`${config.apiUrl}/${payload.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(payload) {
  const requestOptions = {
    ...customRequest("DELETE"),
  };
  return fetch(`${config.apiUrl}/${payload.id}`, requestOptions).then(
    handleResponse
  );
}
