import { authHeader, handleResponse} from "src/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const invoiceNotificationRuleService = {
  getAll,
  getById,
  update,
  create,
  delete: _delete,
};

function getAll(params = {}) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  params.PageSize = 500;
  let queryString = new URLSearchParams(params);
  return fetch(`${config.apiUrl}/InvoiceNotificationRules?${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return { data: response };
    });
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/InvoiceNotificationRules/${id}`, requestOptions).then(
    handleResponse
  );
}

function create(payload) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  return fetch(`${config.apiUrl}/InvoiceNotificationRules`, requestOptions).then(handleResponse);
}

function update(payload) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  return fetch(`${config.apiUrl}/InvoiceNotificationRules/${payload.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(payload) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/InvoiceNotificationRules/${payload.id}`, requestOptions).then(
    handleResponse
  );
}
