import { itemService } from "src/services/api";
import _ from "lodash";
const state = {
  status: {},
  errors: [],
  single: {},
  statusText: "",
  data: [],
  metaData: {},
  dataParam: {
    status: "",
    keyword: "",
    page: "",
    tags: [],
    limit: 50,
  },
  itemOptions: [],

  tableFilter: {
    SearchTerm: null,
    clientId: null,
    dateStart: null,
    dateEnd: null,
    status: null,
  },
  loadingTable: false,
};

const defaultFilter = {
  SearchTerm: null,
  tags: null
};

const actions = {
  async applyTableFilter({ commit, dispatch }, params) {
    commit("setTableFilter", {
      ...defaultFilter,
      ...params,
    });
    dispatch("getAll", params);
  },

  async clearTableFilter({ commit }) {
    commit("setTableFilter", {
      ...defaultFilter,
    });
  },

  async getItemOptions({ commit, dispatch }, payload) {
    await itemService
      .getAll(payload)
      .then((response) => {
        commit("setItemOptions", response?.data || []);
      })
      .catch((responseError) => {
        dispatch("alert/error", responseError, { root: true });
      });
  },

  async addItemOption({ commit, dispatch }, payload) {
    await itemService
      .getAll(payload)
      .then((response) => {
        response.data.map((item) => commit("addItemOption", item));
      })
      .catch((responseError) => {
        dispatch("alert/error", responseError, { root: true });
      });
  },

  async getAll({ commit, dispatch }, queryParam = {}) {
    commit("setLoadingTable", true);
    await itemService.getAll(queryParam).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
        commit("setLoadingTable", false);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("setLoadingTable", false);
      }
    );
  },

  async create({ commit, dispatch }, payload) {
    await itemService.create(payload).then(
      (response) => {
        commit("createSuccess", response);
        dispatch("getAll");
        dispatch("snackbar/success", `Add item successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch }, payload) {
    await itemService.update(payload).then(
      () => {
        dispatch("getAll");
        dispatch("snackbar/success", `Update item successful`, { root: true });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async search({ commit }, params = { keyword: "", page: "", limit: 50 }) {
    commit("setFilter", params);
  },

  async delete({ commit, dispatch }, payload) {
    await itemService.delete(payload).then(
      () => {
        dispatch("getAll");
        dispatch(
          "snackbar/success",
          `Delete item "${payload.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getById({ commit, dispatch }, id) {
    await itemService.getById(id).then(
      (response) => {
        commit("setSingle", response);
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setTableFilter(state, data) {
    state.tableFilter = data;
  },

  setItemOptions(state, data) {
    state.itemOptions = data;
  },

  addItemOption(state, data) {
    const found = state.itemOptions.filter((itemOption) => {
      return itemOption.id === data.id;
    });

    if (found.length === 0) {
      state.itemOptions.push(data);
    }
  },

  clearItemOptions(state) {
    state.itemOptions = [];
  },

  setData(state, data) {
    state.data = data;
  },

  setMetaData(state, data) {
    state.metaData = data;
  },

  setSingle(state, data) {
    state.single = data;
  },

  setFilter(state, param) {
    state.dataParam = param;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = { error: false, message: response.message };
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getItemOptions(state) {
    const itemOptions = [...state.itemOptions];
    return itemOptions;
  },
  getMetaData(state) {
    return state.metaData;
  },
  getAll(state) {
    let data = state.data;
    const { keyword, status, tags } = state.dataParam;
    if (keyword) {
      // data = _.filter(data, keyword);
      data = _.filter(
        data,
        _.flow(
          _.identity,
          _.values,
          _.join,
          _.toLower,
          _.partialRight(_.includes, keyword.toString().toLowerCase())
        )
      );
    }

    if (status) {
      data = _.filter(data, (item) => {
        return item.status == status;
      });
    }

    if (tags && tags.length > 0) {
      data = _.filter(data, (item) => {
        return item.categories.filter((c) => tags.includes(c.id)).length > 0;
      });
    }
    return data;
  },
};

export const item = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
