import { customRequest, handleResponse, accountId } from "src/helpers";
import { buildQueryString } from "src/utils/helpers";
const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const notesService = {
  getAll,
  getById,
  update,
  create,
  delete: _delete,
};

function getAll(params = {}) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  let queryString = buildQueryString(params);
  let url = `${config.apiUrl}/ClientNotes`;
  if (queryString) {
    url += `?${queryString}`;
  }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getById(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/ClientNotes/${id}`, requestOptions).then(
    handleResponse
  );
}

function create(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };

  return fetch(`${config.apiUrl}/ClientNotes`, requestOptions).then(
    handleResponse
  );
}

function update(payload) {
  const requestOptions = {
    ...customRequest("PUT", payload),
  };

  return fetch(
    `${config.apiUrl}/ClientNotes/${payload.id}`,
    requestOptions
  ).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(payload) {
  const requestOptions = {
    ...customRequest("DELETE"),
  };
  return fetch(
    `${config.apiUrl}/ClientNotes/${payload.id}`,
    requestOptions
  ).then(handleResponse);
}
