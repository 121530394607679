import { quoteService } from "src/services/api";

const state = {
  status: {},
  errors: [],
  statusText: "",

  single: {},
  singleAttachments: [],

  data: [],
  metaData: {},

  dataFilter: {
    clientId: "",
    status: "",
  },

  dataParam: {
    keyword: "",
    page: "",
    limit: 50,
  },
  nextNumber: "",

  tableFilter: {
    SearchTerm: null,
    clientId: null,
    status: null,
  },
  loadingTable: false,
};

const defaultFilter = {
  SearchTerm: null,
  clientId: null,
  status: null,
};

const actions = {
  async applyTableFilter({ commit, dispatch }, params) {
    commit("setTableFilter", {
      ...defaultFilter,
      ...params,
    });
    dispatch("getAll", params);
  },

  async clearTableFilter({ commit }) {
    commit("setTableFilter", {
      ...defaultFilter,
    });
  },

  async clearSingleAttachments({ commit }) {
    commit("setSingleAttachments", []);
  },

  async clearFilter({ commit }) {
    commit("clearFilterField", []);
    commit("setFilter", {
      keyword: "",
      page: "",
      limit: 50,
    });
  },

  async addFilterField({ commit }, params) {
    commit("setFilterField", params);
  },

  async search({ commit }, params = { keyword: "", page: "", limit: 50 }) {
    commit("setFilter", params);
  },

  async getAll({ commit, dispatch }, payload) {
    commit("setLoadingTable", true);
    await quoteService.getAll(payload).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
        commit("setLoadingTable", false);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("setLoadingTable", false);
      }
    );
  },

  async getNextNumber({ commit, dispatch }) {
    await quoteService.getNextNumber().then(
      (response) => {
        commit("setNextNumber", response.data);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async create({ commit, dispatch, state }, payload) {
    await quoteService.create(payload).then(
      (response) => {
        commit("createSuccess", response);
        dispatch("getAll", { ...state.tableFilter });
        dispatch("snackbar/success", `Add new quote successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async clone({ commit, dispatch, state }, payload) {
    await quoteService.create(payload).then(
      (response) => {
        commit("createSuccess", response);
        dispatch("getAll", { ...state.tableFilter });
        dispatch("snackbar/success", `Clonning new quote successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch, state }, payload) {
    await quoteService.update(payload).then(
      (response) => {
        commit("updateSuccess", response);
        dispatch("getAll", { ...state.tableFilter });
        dispatch(
          "snackbar/success",
          `Update quote "${response.data.id}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async confirm({ commit, dispatch, state }, payload) {
    await quoteService.confirm(payload.id, payload.value).then(
      (response) => {
        commit("updateSuccess", response);
        dispatch("getAll", { ...state.tableFilter });
        let action = payload.value ? "approved" : "rejected";
        dispatch(
          "snackbar/success",
          `Quote "${payload.id}" successfully ${action}`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async delete({ commit, dispatch }, payload) {
    await quoteService.delete(payload).then(
      (response) => {
        commit("deleteSuccess", response);
        commit("deleteData", response.data);

        dispatch(
          "snackbar/success",
          `Delete quote "${payload.id}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
        dispatch("snackbar/error", responseError, { root: true });
      }
    );
  },

  async convertItemsToTask({ commit, dispatch }, payload) {
    await quoteService.convertItemsToTask(payload).then(
      (response) => {
        console.log("response convert", response);
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getById({ commit, dispatch }, id) {
    await quoteService.getById(id).then(
      (response) => {
        commit("setSingle", response.data);
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async sendEmail({ commit, dispatch }, payload) {
    await quoteService.sendEmail(payload).then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Send email quote successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getAttachments({ commit }, id) {
    commit("setLoadingTable", true);
    await quoteService.getByIdFiles(id).then(
      (response) => {
        let { data } = response;
        commit("setSingleAttachments", data);
        commit("setLoadingTable", false);
      },
      (responseError) => {
        commit("createFailure", responseError);
        commit("setLoadingTable", false);
      }
    );
  },

  async addAttachments({ commit, dispatch }, payload) {
    commit("setLoadingAction", true);
    await quoteService.addAttachments(payload).then(
      (response) => {
        commit("setLoadingAction", false);
        let formattedResponse = {
          data: response,
          message: "Update successfull",
        };
        commit("updateSuccess", formattedResponse);
        commit("updateData", formattedResponse.data);
        dispatch(
          "snackbar/success",
          `Associating files to invoice successful`,
          {
            root: true,
          }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
        commit("setLoadingAction", false);
      }
    );
  },

  async deleteAttachments({ commit, dispatch }, payload) {
    commit("setLoadingAction", true);
    await quoteService.deleteAttachments(payload).then(
      () => {
        commit("setLoadingAction", false);
        dispatch(
          "snackbar/success",
          `Delete associated files of invoice successful`,
          {
            root: true,
          }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
        commit("setLoadingAction", true);
      }
    );
  },

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setSingleAttachments(state, data) {
    let _data = data.map((item) => {
      return {
        ...item,
        isFolder: false,
        icon: "mdi-file",
        parentId: item.parentId ? item.parentId : "",
      };
    });
    state.singleAttachments = _data;
  },

  setTableFilter(state, data) {
    state.tableFilter = data;
  },

  setSingle(state, data) {
    state.single = data;
  },

  clearFilterField(state) {
    state.dataFilter = [];
  },

  setFilterField(state, param) {
    state.dataFilter = param;
  },

  setFilter(state, param) {
    state.dataParam = param;
  },

  setNextNumber(state, data) {
    state.nextNumber = data;
  },

  setData(state, data) {
    state.data = data;
  },

  setMetaData(state, data) {
    state.metaData = data;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = { error: false, message: response.message };
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getAll(state) {
    return state.data;
  },
  getMetaData(state) {
    return state.metaData;
  },
};

export const quote = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
