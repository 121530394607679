import { vaultFolderService } from "src/services/api";

const state = {
  status: {},
  errors: [],
  statusText: "",
  data: [],
};

const actions = {
  async getAll({ commit, dispatch }) {
    await vaultFolderService.getAll().then(
      (response) => {
        commit("setData", response);
      },
      (error) => {
        dispatch("snackbar/error", error, { root: true });
      }
    );
  },

  async create({ commit, dispatch }, payload) {
    await vaultFolderService.create(payload).then(
      (response) => {
        commit("createSuccess", response);
        // commit("appendData", response.data);
        dispatch("getAll");
        dispatch("snackbar/success", `Add item "${payload.name}" successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch }, payload) {
    await vaultFolderService.update(payload).then(
      () => {
        dispatch("getAll");
        dispatch(
          "snackbar/success",
          `Update item "${payload.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async delete({ commit, dispatch }, payload) {
    await vaultFolderService.delete(payload).then(
      () => {
        dispatch("getAll");
        dispatch(
          "snackbar/success",
          `Delete item "${payload.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  getById() {},
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = { error: false, message: response.message };
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getNestedIds(state, currentItem) {
    let found = state.data.filter((item) => {
      return item.id == currentItem.parentId;
    });

    if (found && found.length === 1 && found[0].id) {
      let found2 = getters.getNestedIds(state, found[0]);
      return found2 + "|" + currentItem.id;
    }
    return currentItem.id;
  },

  getNestedName(state, currentItem) {
    let found = state.data.filter((item) => {
      return item.id == currentItem.parentId;
    });

    if (found && found.length === 1 && found[0].id) {
      let found2 = getters.getNestedName(state, found[0]);
      return found2 + " / " + currentItem.name;
    }
    return currentItem.name;
  },

  getChildren(state, parentId) {
    return state.data
      .filter((item) => {
        return item.parentId == parentId;
      })
      .map((item) => {
        return {
          ...item,
          pathIds: getters.getNestedIds(state, item),
          pathName: getters.getNestedName(state, item),
          children: getters.getChildren(state, item.id),
        };
      });
  },

  getNestedAll(state) {
    let parents = state.data.filter((item) => {
      return item.parentId == 0;
    });

    return parents.map((item) => {
      return {
        ...item,
        children: getters.getChildren(state, item.id),
      };
    });
  },

  getAll(state) {
    return state.data
      .map((item) => {
        return {
          ...item,
          pathIds: getters
            .getNestedIds(state, item)
            .toString()
            .split("|"),
          pathName: getters.getNestedName(state, item),
        };
      })
      .sort((a, b) => {
        if (a.pathName < b.pathName) {
          return -1;
        }
        if (a.pathName > b.pathName) {
          return 1;
        }
        return 0;
      });
  },
};

export const vaultFolder = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
