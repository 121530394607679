const UTIL = {
  gv: (o, p, defaultVal = null) => {
    let _def = typeof defaultVal !== "undefined" ? defaultVal : "-";
    return p.split(".").reduce((xs, x) => {
      return xs && xs[x] ? xs[x] : _def;
    }, o);
  },
};

export default UTIL;
