import { invoiceService } from "src/services/api";

const state = {
  status: {},
  errors: [],
  statusText: "",

  invoice: {},

  data: [],
  metaData: {},

  tableFilter: {
    SearchTerm: null,
    clientId: null,
    dateStart: null,
    dateEnd: null,
    status: null,
  },

  loadingTable: false,
};

const defaultFilter = {
  SearchTerm: null,
  clientId: null,
  dateStart: null,
  dateEnd: null,
  status: null,
};

const actions = {
  async applyTableFilter({ commit, dispatch }, params) {
    commit("setTableFilter", {
      ...defaultFilter,
      ...params,
    });
    dispatch("getAll", params);
  },

  async clearTableFilter({ commit }) {
    commit("setTableFilter", {
      ...defaultFilter,
    });
  },

  async getAll({ commit, dispatch }, queryParam = {}) {
    commit("setLoadingTable", true);
    queryParam.status = "draft,overdue,partially_paid,sent,unpaid";
    await invoiceService.getAll(queryParam).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
        commit("setLoadingTable", false);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("setLoadingTable", false);
      }
    );
  },

  async getInvoiceById({ commit, dispatch }, id) {
    await invoiceService.getById(id).then(
      (response) => {
        if(response.data.status === "paid") {
          dispatch(
            "snackbar/success",
            `Invoice #${response.data.id} has already been paid`,
            {
              root: true,
            }
          );
          commit("setInvoice", {});
        } else {
          commit("setInvoice", response.data);
        }
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async setInvoiceByObj({ commit, dispatch }, data) {
    if(data.status === "paid") {
      dispatch(
        "snackbar/success",
        `Invoice #${data.id} has already been paid`,
        {
          root: true,
        }
      );
      commit("setInvoice", {});
    } else {
      commit("setInvoice", data);
    }
  },

  async clearInvoice({ commit }) {
    commit("setInvoice", {});
  },

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setTableFilter(state, data) {
    state.tableFilter = data;
  },

  setMetaData(state, data) {
    state.metaData = data;
  },

  setInvoice(state, data) {
    state.invoice = data;
  },

  setData(state, data) {
    state.data = data;
  },

  statusOk(state) {
    state.statusText = "ok";
    state.status = { error: false, message: "ok" };
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = { error: false, message: response.message };
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getTableFilter(state) {
    return state.tableFilter;
  },
  getAll(state) {
    let data = state.data;
    return data;
  },
  getMetaData(state) {
    let data = state.metaData;
    return data;
  },
};

export const pay = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
