import { timeclockService } from "src/services/api";

const state = {
  status: {},
  errors: [],
  statusOptions: [
    {
      value: "open",
      text: "Open",
    },
    {
      value: "closed",
      text: "Closed",
    },
  ],
  typeOptions: [
    {
      value: "onsite",
      text: "Onsite Support",
    },
    {
      value: "remote",
      text: "Remote Support",
    },
  ],
  statusText: "",
  data: [],
  metaData: {},
  single: {},

  tableFilter: {
    SearchTerm: null,
    clientId: null,
    dateStart: null,
    dateEnd: null,
    tags: null,
    isTimeClockRunning: null,
    hideCompleted: null,
    status: null,
  },
  loadingTable: false,
};

const defaultFilter = {
  SearchTerm: null,
  clientId: null,
  dateStart: null,
  dateEnd: null,
  tags: null,
  isTimeClockRunning: null,
  hideCompleted: null,
  status: null,
};

const actions = {
  async applyTableFilter({ commit, dispatch }, params) {
    commit("setTableFilter", {
      ...defaultFilter,
      ...params,
    });
    dispatch("getAll", params);
  },

  async clearTableFilter({ commit }) {
    commit("setTableFilter", {
      SearchTerm: null,
      clientId: null,
      dateStart: null,
      dateEnd: null,
      status: null,
    });
  },

  async clearAll({ commit }) {
    commit("setData", []);
  },

  async clearFilter({ commit }) {
    commit("clearFilterField", []);
    commit("setFilter", {
      keyword: "",
      page: "",
      limit: 50,
    });
  },

  async addFilterField({ commit }, params) {
    commit("setFilterField", params);
  },

  async entryCreate({ commit, dispatch }, params) {
    await timeclockService.entryCreate(params).then(
      (response) => {
        commit("setSingle", response.data);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async entryUpdate({ commit, dispatch }, params) {
    await timeclockService.entryUpdate(params).then(
      (response) => {
        commit("setSingle", response.data);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async getAll({ commit, dispatch }, params = {}) {
    commit(
      "setLoadingTable",
      params["noLoading"] ? params["noLoading"] : false
    );
    await timeclockService.getAll({ ...params }).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
        commit("setLoadingTable", false);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async create({ commit, dispatch }, payload) {
    await timeclockService.create(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Create successfull",
        };

        commit("createSuccess", formattedResponse);
        dispatch("snackbar/success", `Add Time Clock successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async createFromTask({ commit, dispatch }, payload) {
    await timeclockService.createFromTask(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Create successfull",
        };

        commit("createSuccess", formattedResponse);

        dispatch("snackbar/success", `Add Time Clock successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch }, payload) {
    await timeclockService.update(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Update successfull",
        };

        commit("updateSuccess", formattedResponse);
        dispatch("snackbar/success", `Update Time Clock successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async delete({ commit, dispatch }, payload) {
    await timeclockService.delete(payload).then(
      (response) => {
        commit("deleteSuccess", response);
        // commit("deleteData", response.data);
        dispatch("snackbar/success", `Delete Time Clock successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async generateReport({ dispatch }, payload) {
    await timeclockService.generateReport(payload).then(
      () => {
        dispatch(
          "snackbar/success",
          `Timeclock report generated successfully`,
          {
            root: true,
          }
        );
      },
      (responseError) => {
        dispatch("snackbar/error", responseError, { root: true });
      }
    );
  },

  async getById({ commit }, id) {
    await timeclockService.getById(id).then(
      (response) => {
        commit("setSingle", response);
        // dispatch(
        //   "snackbar/success",
        //   `Add user group "${payload.name}" successful`,
        //   { root: true }
        // );
      },
      (responseError) => {
        commit("createFailure", responseError);
        // dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setTableFilter(state, data) {
    state.tableFilter = data;
  },

  setData(state, data) {
    state.data = data;
  },
  setMetaData(state, data) {
    state.metaData = data;
  },

  setSingle(state, data) {
    state.single = data;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = {
      error: false,
      message: response.message,
    };
    state.single = response.data;
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getAll(state) {
    let data = state.data;
    return data;
  },
  getMetaData(state) {
    let data = state.metaData;
    return data;
  },
  getType: (state) => (value) => {
    return state.typeOptions.find((type) => type.value == value);
  },
};

export const timeclockImport = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
