import { notesService } from "src/services/api";
import _ from "lodash";
const state = {
  status: {},
  errors: [],
  statusText: "",
  data: [],
  metaData: {},
  dataParam: {
    status: "",
    keyword: "",
    page: "",
    tags: [],
    limit: 50,
  },
};

const actions = {
  async getAll({ commit, dispatch }, params = {}) {
    await notesService.getAll(params).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async create({ commit, dispatch }, payload) {
    await notesService.create(payload).then(
      (response) => {
        commit("createSuccess", response);
        // commit("appendData", response.data);
        dispatch("getAll");
        dispatch("snackbar/success", `Add notes successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch }, payload) {
    await notesService.update(payload).then(
      () => {
        dispatch("getAll");
        dispatch("snackbar/success", `Update notes successful`, { root: true });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async search({ commit }, params = { keyword: "", page: "", limit: 50 }) {
    commit("setFilter", params);
  },

  async delete({ commit, dispatch }, payload) {
    await notesService.delete(payload).then(
      () => {
        dispatch("getAll");
        dispatch(
          "snackbar/success",
          `Delete notes "${payload.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  getById() {},

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },

  setMetaData(state, data) {
    state.metaData = data;
  },

  setFilter(state, param) {
    state.dataParam = param;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = { error: false, message: response.message };
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getMetaData(state) {
    return state.metaData;
  },
  getAll(state) {
    let data = state.data;
    const { keyword, status, tags } = state.dataParam;
    if (keyword) {
      // data = _.filter(data, keyword);
      data = _.filter(
        data,
        _.flow(
          _.identity,
          _.values,
          _.join,
          _.toLower,
          _.partialRight(_.includes, keyword.toString().toLowerCase())
        )
      );
    }

    if (status) {
      data = _.filter(data, (item) => {
        return item.status == status;
      });
    }

    if (tags && tags.length > 0) {
      data = _.filter(data, (item) => {
        return item.categories.filter((c) => tags.includes(c.id)).length > 0;
      });
    }
    return data;
  },
};

export const notes = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
