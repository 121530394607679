<template>
  <v-dialog v-model="dialog" persistent width="30vw">
    <v-card>
      <v-card-title>Session Timeout</v-card-title>
      <v-card-text>
        <div class="p-5 alert-danger">
          Your session timed out, Please relogin
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="ok()">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { userService } from "src/services/api";
import { removeStorage } from "src/utils/helpers";

const SECOND = 1000;

export default {
  data() {
    return {
      dialog: false,
      hours: 0,
      minutes: 0,
      seconds: 0,
      hourtime: "",
      user: null,
      h: 0,
      duration: 0,
    };
  },
  mounted() {
    this.$options.timer = window.setTimeout(this.updateDateTime, SECOND);
  },
  beforeDestroy() {
    window.clearTimeout(this.$options.timer);
  },
  computed: {
    expiration() {
      return this.user.expiration;
    },
    remainSecond() {
      return parseInt(this.duration);
    },
    us() {
      return this.$route.params;
    },
  },
  watch: {
    h(n) {
      if (n < 5) {
        this.dialog = true;
      }
    },

    us(n) {
      if (n) {
        this.$options.timer = window.setTimeout(this.updateDateTime, SECOND);
      }
    },

    remainSecond(n) {
      if (this.user && n <= 5) {
        userService.logout();
        removeStorage('2FA_VERIFIED');
        this.ok();
        // this.dialog = true;
      } else {
        // clearTimeout(this.timer);
        // this.timer = null;
      }
    },
  },
  methods: {
    ok() {
      window.location.href = "/login";
    },
    pad(num) {
      return ("0" + num).slice(-2);
    },
    hhmmss(secs) {
      var minutes = Math.floor(secs / 60);
      secs = secs % 60;
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
    },
    updateDateTime() {
      this.user = JSON.parse(localStorage.getItem("user"));
      if (!this.user) {
        return false;
      }
      let now2 = moment(new Date());
      let end = moment(this.expiration);
      let duration = moment.duration(end.diff(now2));
      this.duration = duration.asSeconds();
      this.h = this.hhmmss(parseInt(this.duration));
      this.$options.timer = window.setTimeout(this.updateDateTime, SECOND);
    },
  },
};
</script>
