import {
  customRequest,
  customRequestArray,
  handleResponse,
  accountId,
} from "src/helpers";
import { buildQueryString } from "src/utils/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const quoteService = {
  create,
  update,
  getAll,
  getById,
  delete: _delete,
  getNextNumber,
  convertItemsToTask,
  confirm,
  sendEmail,
  addAttachments,
  deleteAttachments,
  getByIdFiles,
};

function getByIdFiles(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/Quotes/${id}/files`, requestOptions).then(
    handleResponse
  );
}

function addAttachments(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequestArray("POST", payload.attachmentIds),
  };

  return fetch(
    `${config.apiUrl}/Quotes/${payload.id}/files`,
    requestOptions
  ).then(handleResponse);
}

function deleteAttachments(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequestArray("DELETE", payload.attachmentIds),
  };

  return fetch(
    `${config.apiUrl}/Quotes/${payload.id}/files`,
    requestOptions
  ).then(handleResponse);
}

function create(payload) {
  payload.quote.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(`${config.apiUrl}/Quotes/CreateWithLines`, requestOptions).then(
    handleResponse
  );
}

function sendEmail(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Quotes/${payload.id}/email`,
    requestOptions
  ).then(handleResponse);
}

function getNextNumber() {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/Quotes/NextNumber`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function getAll(params = {}) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  let queryString = buildQueryString(params);
  return fetch(`${config.apiUrl}/Quotes?${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getById(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/Quotes/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function update(payload) {
  let id = payload.id;
  delete payload.id;
  payload.quote.accountId = accountId();
  const requestOptions = {
    ...customRequest("PUT", payload),
  };

  return fetch(`${config.apiUrl}/Quotes/${id}/UpdateWithLines`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function confirm(id, payload) {
  const requestOptions = {
    ...customRequestArray("PUT", payload),
  };

  return fetch(`${config.apiUrl}/Quotes/${id}/Confirm`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(payload) {
  const requestOptions = {
    ...customRequest("DELETE"),
  };
  return fetch(`${config.apiUrl}/Quotes/${payload.id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: {
          id: payload.id,
          ...response,
        },
      };
    });
}

function convertItemsToTask(payload) {
  const requestOptions = {
    ...customRequestArray("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/LineItems/ConvertQuoteLineItemsToTasks`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      console.log(response.data);
      return {
        data: response.data,
      };
    });
}
