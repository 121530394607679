import { reportService } from "src/services/api";

const state = {
  status: {},
  errors: [],

  profitLossData: [],
};

const actions = {
  async profitLossExport({ dispatch }, payload) {
    await reportService.profitLossExport(payload).then(
      () => {
        dispatch(
          "snackbar/success",
          `Profit & Loss report exported successfully`,
          {
            root: true,
          }
        );
      },
      (responseError) => {
        dispatch("snackbar/error", responseError, { root: true });
      }
    );
  },

  async profitLossJson({ dispatch, commit }, payload) {
    await reportService.profitLossJson(payload).then(
      (response) => {
        commit("setProfitLossData", response);
      },
      (responseError) => {
        dispatch("snackbar/error", responseError, { root: true });
      }
    );
  },

  async timeclocksExport({ dispatch }, payload) {
    await reportService.timeclocksExport(payload).then(
      () => {
        dispatch(
          "snackbar/success",
          `Timeclocks report exported successfully`,
          { root: true },
        );
      },
      (responseError) => {
        dispatch("snackbar/error", responseError, { root: true });
      }
    );
  },

  async timeclocksJson({ dispatch }, payload) {
    let response = null;
    await reportService.timeclocksJson(payload).then(
      (okResponse) => {
        response = okResponse;
      },
      (responseError) => {
        dispatch("snackbar/error", responseError, { root: true });
      }
    );
    return response;
  },

  async activeClientsExport({ dispatch }, payload) {
    await reportService.activeClientsExport(payload).then(
      () => {
        dispatch(
          "snackbar/success",
          `Active Clients report exported successfully`,
          { root: true },
        );
      },
      (responseError) => {
        dispatch("snackbar/error", responseError, { root: true });
      }
    );
  },

  async activeClientsJson({ dispatch }, payload) {
    let response = null;
    await reportService.activeClientsJson(payload).then(
      (okResponse) => {
        console.log('activeClients okResponse', okResponse)
        response = okResponse;
      },
      (responseError) => {
        dispatch("snackbar/error", responseError, { root: true });
      }
    );
    return response;
  },
};

const mutations = {
  setProfitLossData(state, data) {
    state.profitLossData = data;
  },
};

const getters = {};

export const report = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
