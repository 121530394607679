import { timeclockEntryService } from "src/services/api";
import _ from "lodash";

const state = {
  status: {},
  errors: [],
  statusText: "",
  data: [],
  dataFilter: {
    clientId: "",
    status: "",
  },
  dataParam: {
    keyword: "",
    page: "",
    limit: 50,
  },
  single: {},
};

const actions = {
  async clearAll({ commit }) {
    commit("setData", []);
  },

  async clearFilter({ commit }) {
    commit("clearFilterField", []);
    commit("setFilter", {
      keyword: "",
      page: "",
      limit: 50,
    });
  },

  async addFilterField({ commit }, params) {
    commit("setFilterField", params);
  },

  async create({ commit, dispatch }, params) {
    await timeclockEntryService.create(params).then(
      (response) => {
        commit("setSingle", response.data);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async update({ commit, dispatch }, params) {
    await timeclockEntryService.update(params).then(
      (response) => {
        commit("setSingle", response.data);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async search({ commit }, params = { keyword: "", page: "", limit: 50 }) {
    commit("setFilter", params);
  },

  async delete({ commit, dispatch }, payload) {
    await timeclockEntryService.delete(payload).then(
      (response) => {
        commit("deleteSuccess", response);
        // commit("deleteData", response.data);
        dispatch("snackbar/success", `Delete Time Clock successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getById({ commit }, id) {
    await timeclockEntryService.getById(id).then(
      (response) => {
        commit("setSingle", response);
        // dispatch(
        //   "snackbar/success",
        //   `Add user group "${payload.name}" successful`,
        //   { root: true }
        // );
      },
      (responseError) => {
        commit("createFailure", responseError);
        // dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },

  clearFilterField(state) {
    state.dataFilter = [];
  },

  setFilterField(state, param) {
    state.dataFilter = param;
  },

  setFilter(state, param) {
    state.dataParam = param;
  },

  setSingle(state, data) {
    state.single = data;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = {
      error: false,
      message: response.message,
    };
    state.single = response.data;
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getAll(state) {
    let data = state.data;
    const { keyword } = state.dataParam;
    if (keyword) {
      // data = _.filter(data, keyword);
      data = _.filter(
        data,
        _.flow(
          _.identity,
          _.values,
          _.join,
          _.toLower,
          _.partialRight(_.includes, keyword.toString().toLowerCase())
        )
      );
    }

    const { status, clientId } = state.dataFilter;

    if (status) {
      data = _.filter(data, (item) => {
        return item.status == status;
      });
    }

    if (clientId) {
      data = _.filter(data, (item) => {
        return item.clientId == clientId;
      });
    }

    return data;
  },
  getType: (state) => (value) => {
    return state.typeOptions.find((type) => type.value == value);
  },
};

export const timeclockEntry = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
