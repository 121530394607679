import {
  customRequest,
  customRequestArray,
  handleResponse,
  accountId,
} from "src/helpers";
import { buildQueryString } from "src/utils/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const gginvoiceService = {
  create,
  update,
  getAll,
  getById,
  getPdfById,
  delete: _delete,
  getNextNumber,
  sendEmail,
  logPayment,
  getStatementAll,
  getStatementSummary,
  addAttachments,
  deleteAttachments,
  getByIdFiles,
  void: _void,
};
function getByIdFiles(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/Gginvoices/${id}/files`, requestOptions).then(
    handleResponse
  );
}

function addAttachments(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequestArray("POST", payload.attachmentIds),
  };

  return fetch(
    `${config.apiUrl}/Gginvoices/${payload.id}/files`,
    requestOptions
  ).then(handleResponse);
}

function deleteAttachments(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequestArray("DELETE", payload.attachmentIds),
  };

  return fetch(
    `${config.apiUrl}/Gginvoices/${payload.id}/files`,
    requestOptions
  ).then(handleResponse);
}

function create(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Gginvoices`,
    requestOptions
  ).then(handleResponse);
}

function logPayment(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Gginvoices/${payload.id}/payment`,
    requestOptions
  ).then(handleResponse);
}

function sendEmail(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Gginvoices/${payload.id}/email`,
    requestOptions
  ).then(handleResponse);
}

function getNextNumber() {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/Gginvoices/NextNumber`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function getAll(params = {}) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  let queryString = buildQueryString(params);
  return fetch(`${config.apiUrl}/Gginvoices?${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getStatementSummary(params = {}) {
  let queryString = buildQueryString(params);
  const requestOptions = {
    ...customRequest("GET"),
  };
  return fetch(
    `${config.apiUrl}/Gginvoices/Summary?${queryString}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function getStatementAll(params = {}) {
  let queryString = buildQueryString(params);

  const requestOptions = {
    ...customRequest("GET"),
  };
  return fetch(
    `${config.apiUrl}/Gginvoices/Statement?${queryString}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function getById(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/Gginvoices/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function getPdfById(id, admin) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  if (admin) return fetch(`${config.apiUrl}/Gginvoices/${id}/download/admin`, requestOptions)
  else return fetch(`${config.apiUrl}/Gginvoices/${id}/download`, requestOptions)
}

function update(payload) {
  let id = payload.id;
  const requestOptions = {
    ...customRequest("PUT", payload),
  };

  return fetch(
    `${config.apiUrl}/Gginvoices/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function _void(payload) {
  const requestOptions = {
    ...customRequest("POST", payload?.note ?? ''),
  };
  return fetch(`${config.apiUrl}/Gginvoices/${payload.id}/void`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: {
          id: payload.id,
          ...response,
        },
      };
    });
}

function _delete(payload) {
  const requestOptions = {
    ...customRequest("DELETE"),
  };
  return fetch(`${config.apiUrl}/Gginvoices/${payload.id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: {
          id: payload.id,
          ...response,
        },
      };
    });
}
