import { storageService } from "src/services/api";

// let permissions = [
//   {
//     userGroupId: 83,
//     userActionId: 1,
//     access: true,
//   },
//   {
//     userGroupId: 83,
//     userActionId: 3,
//     access: false,
//   },
//   {
//     userGroupId: 83,
//     userActionId: 4,
//     access: true,
//   },
//   {
//     userGroupId: 50,
//     userActionId: 1,
//     access: false,
//   },
//   {
//     userGroupId: 50,
//     userActionId: 3,
//     access: false,
//   },
//   {
//     userGroupId: 50,
//     userActionId: 4,
//     access: false,
//   },
// ];

const state = {
  status: {},
  errors: [],
  statusText: "",
  data: [],
  metaData: {},

  dataFile: [],
  dataFolder: [],

  dataParam: {
    status: "",
    keyword: "",
    page: "",
    tags: [],
    limit: 50,
  },
  single: {},

  tableFilter: {
    SearchTerm: null,
    clientId: null,
    tags: null,
    isTimeClockRunning: null,
    hideCompleted: null,
    status: null,
  },
  loadingTable: false,
  loadingAction: false,
};

const defaultFilter = {
  SearchTerm: null,
  clientId: null,
  tags: null,
  isTimeClockRunning: null,
  hideCompleted: null,
  status: null,
};

const actions = {
  async applyTableFilter({ commit, dispatch }, params) {
    commit("setTableFilter", {
      ...defaultFilter,
      ...params,
    });
    dispatch("getAll", params);
  },

  async clearTableFilter({ commit }) {
    commit("setTableFilter", {
      SearchTerm: null,
      clientId: null,
      dateStart: null,
      dateEnd: null,
      status: null,
    });
  },

  async clearData({ commit }) {
    commit("setData", []);
  },

  async clearFilter({ commit }) {
    commit("setFilter", {
      keyword: "",
      page: "",
      limit: 50,
    });
  },

  async getAll({ commit }, params = {}) {
    commit("setLoadingTable", true);
    let response = await storageService.listAll(params);

    let { files, folders } = response;
    let filteredFiles = files.map((item) => {
      return { ...item, isFolder: false, icon: "description" };
    });
    let filteredFolders = folders.map((item) => {
      return {
        ...item,
        isFolder: true,
        icon: "mdi-folder",
        parentId: item.parentId ? item.parentId : "",
      };
    });
    commit("setData", [...filteredFolders, ...filteredFiles]);
    commit("setLoadingTable", false);
  },

  async search({ commit }, params = { keyword: "", page: "", limit: 50 }) {
    commit("setFilter", params);
  },

  async create({ commit, dispatch }, payload) {
    await storageService.create(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Create successfull",
        };
        commit("setSingle", response);
        commit("createSuccess", formattedResponse);
        dispatch("snackbar/success", `Add task "${response.name}" successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async upload({ commit, dispatch }, payload) {
    await storageService.upload(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Create successfull",
        };
        commit("setSingle", response);
        commit("createSuccess", formattedResponse);
        dispatch("snackbar/success", `Add task "${response.name}" successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch }, payload) {
    await storageService.update(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Update successfull",
        };

        commit("updateSuccess", formattedResponse);
        commit("updateData", formattedResponse.data);
        dispatch(
          "snackbar/success",
          `Update vault entry "${payload.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async updateStatus({ commit, dispatch }, payload) {
    await storageService.updateStatus(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Update successfull",
        };

        commit("updateSuccess", formattedResponse);
        commit("updateData", formattedResponse.data);
        dispatch(
          "snackbar/success",
          `Update vault entry "${response.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async delete({ commit, dispatch }, payload) {
    await storageService.delete(payload).then(
      (response) => {
        commit("deleteSuccess", response);
        // commit("deleteData", response.data);
        dispatch(
          "snackbar/success",
          `Delete task "${payload.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getById({ commit }, id) {
    await storageService.getById(id).then(
      (response) => {
        commit("setSingle", response);
        // dispatch(
        //   "snackbar/success",
        //   `Add user group "${payload.name}" successful`,
        //   { root: true }
        // );
      },
      (responseError) => {
        commit("createFailure", responseError);
        // dispatch("alert/error", responseError, { root: true });
      }
    );
  },
};

const mutations = {
  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setLoadingAction(state, data) {
    state.loadingAction = data;
  },

  setTableFilter(state, data) {
    state.tableFilter = data;
  },

  setData(state, data) {
    state.data = data;
  },

  setMetaData(state, data) {
    state.metaData = data;
  },

  setFilter(state, param) {
    state.dataParam = param;
  },

  setSingle(state, data) {
    state.single = data;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = {
      error: false,
      message: response.message,
    };
    state.single = response.data;
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

function checkAccess(file, rootState, userActionId) {
  let currentUserGroupId = rootState["account"].data.permissions.id;
  let isAdmin = rootState["account"].data.isAdmin;
  let myPerms = file.permissions;
  let hasAccess = myPerms.filter(
    (p) =>
      p.userGroupId == currentUserGroupId &&
      (p.access == true) & (p.userActionId == userActionId)
  );
  return hasAccess.length > 0 || isAdmin;
}

const getters = {
  getMetaData(state) {
    return state.metaData;
  },
  getAll(state, current, rootState) {
    current;
    let data = state.data;

    let filteredData = data.filter((file) => {
      return checkAccess(file, rootState, 1);
    });

    return filteredData.map((fileOrFolder) => {
      return {
        ...fileOrFolder,
        canDownload: checkAccess(fileOrFolder, rootState, 1), // view
        canEdit: checkAccess(fileOrFolder, rootState, 3), //edit
        canDelete: checkAccess(fileOrFolder, rootState, 4), //delete
      };
    });
  },
  getType: (state) => (value) => {
    return state.typeOptions.find((type) => type.value == value);
  },
};

export const file = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
