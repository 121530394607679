export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  } else {
    return {};
  }
}

export function customRequestArray(method = "GET", payload = []) {
  let user = JSON.parse(localStorage.getItem("user"));
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  let body = payload;
  if (user && user.token) {
    headers["Authorization"] = "Bearer " + user.token;
  }

  let requestOptions = {
    method,
    headers: headers,
  };

  if (method !== "GET") {
    requestOptions.body = JSON.stringify(body);
  }

  return requestOptions;
}

export function customRequestUpload(method = "POST", payload = []) {
  let user = JSON.parse(localStorage.getItem("user"));
  let headers = {
    Accept: "application/json",
    // "Content-Type": "multipart/form-data",
    Charset: "utf-8",
  };

  let body = payload;
  if (user && user.token) {
    headers["Authorization"] = "Bearer " + user.token;
  }

  let requestOptions = {
    method,
    headers: headers,
  };

  requestOptions.body = body;

  return requestOptions;
}

export function customRequest(method = "GET", payload = {}) {
  let user = JSON.parse(localStorage.getItem("user"));
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  let body = typeof payload == 'string' ? payload : { ...payload };
  if (user && user.token) {
    headers["Authorization"] = "Bearer " + user.token;
    if (typeof payload == 'object') {
      body.accountId = user.user.accountId;
    }
  }

  let requestOptions = {
    method,
    headers: headers,
  };

  if (method !== "GET") {
    requestOptions.body = JSON.stringify(body);
  }

  return requestOptions;
}

export function accountId() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));
  return user.user.accountId;
}

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        // location.reload(true);
      }
      const error =
        (data && data.message) || `${response.status}: ${response.statusText}`;
      return Promise.reject(error);
    }
    return data;
  });
}