import { customRequest, handleResponse } from "src/helpers";
import { buildQueryString } from "src/utils/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const auditLogService = {
  getAll,
  getById,
};

function getAll(params = {}) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  let { accountIds, userIds } = params;
  delete params.accountIds;
  delete params.userIds;
  let queryParams = buildQueryString(params);

  if (accountIds && accountIds.length > 0) {
    accountIds.map((x) => {
      queryParams.append("accountIds", x);
    });
  }

  if (userIds && userIds.length > 0) {
    userIds.map((x) => {
      queryParams.append("userIds", x);
    });
  }

  const queryString = queryParams.toString();

  return fetch(`${config.apiUrl}/AuditTrails?${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getById(id) {
  const requestOptions = {
    ...customRequest('GET')
  };

  return fetch(`${config.apiUrl}/AuditTrails/${id}`, requestOptions).then(
    handleResponse
  );
}
