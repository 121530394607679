import { customRequest, handleResponse, customRequestArray } from "src/helpers";
import { buildQueryString } from "src/utils/helpers";
import store from "src/store";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  changePassword,
  changeAdminPassword,
  requestPassword,
  getTaxRate,
  reactivate,
  requestEmailVerification,
  verifyEmail,
  requestPhoneVerification,
  verifyPhone,
  reloadAccount,
  request2FAVerification,
  verify2FA,
  set2FASetting,
};

async function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username: username, password: password }),
  };

  return await fetch(`${config.apiUrl}/Authenticate/login`, requestOptions)
    .then(handleResponse)
    .then(async (response) => {
      // login successful if there's a jwt token in the response
      let { permissions } = response;
      if (!permissions) {
        permissions = {};
      }

      if (response.token) {
        let userLogged = {
          ...response,
          permissions,
        };
        localStorage.setItem("user", JSON.stringify(userLogged));
        return userLogged;
      }
    });
}

async function reloadAccount() {
  let item = JSON.parse(localStorage.getItem("user"));
  let userId = item.user.id;
  let { data } = await getById(userId);
  localStorage.setItem(
    "user",
    JSON.stringify({
      ...item,
      user: data,
    })
  );
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("2FA_VERIFIED");
  store.dispatch("item/clearData");
  store.commit("item/clearItemOptions");
  window.location.reload(true);
}

function register(payload) {
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(`${config.apiUrl}/Authenticate/register`, requestOptions).then(
    handleResponse
  );
}

function getAll(payload) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  let queryString = buildQueryString(payload);

  return fetch(`${config.apiUrl}/Users/Account?${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getTaxRate() {
  const requestOptions = {
    ...customRequest("GET"),
  };
  return fetch(`${config.apiUrl}/Accounts/SalesTaxRate`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function getById(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  return fetch(`${config.apiUrl}/Users/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response.user,
      };
    });
}

function update(payload) {
  const requestOptions = {
    ...customRequest("PUT", payload),
  };
  return fetch(`${config.apiUrl}/Users/${payload.id}`, requestOptions).then(
    handleResponse
  );
}

function reactivate(payload) {
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Users/reactivate/${payload.id}`,
    requestOptions
  ).then(handleResponse);
}

function _delete(payload) {
  const requestOptions = {
    ...customRequest("DELETE", payload),
  };
  return fetch(`${config.apiUrl}/Users/${payload.id}`, requestOptions).then(
    handleResponse
  );
}

function requestPassword(payload) {
  const requestOptions = {
    ...customRequestArray("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Authenticate/forgotPassword`,
    requestOptions
  ).then(handleResponse);
}

function changePassword(payload, currentUser = true) {
  if (currentUser) {
    const item = JSON.parse(localStorage.getItem("user"));
    const username = item.user.userName;
    payload = {...payload, username}
  }

  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Authenticate/changePassword`,
    requestOptions
  ).then(handleResponse);
}

function changeAdminPassword(payload) {
  const item = JSON.parse(localStorage.getItem("user"));
  const username = item.user.userName;

  const requestOptions = {
    ...customRequest("POST", {...payload, username}),
  };
  return fetch(
    `${config.apiUrl}/Authenticate/changeAdminPassword`,
    requestOptions
  ).then(handleResponse);
}

function requestEmailVerification(payload = {}) {
  const requestOptions = {
    ...customRequestArray("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Authenticate/RequestEmailVerification`,
    requestOptions
  ).then(handleResponse);
}

function verifyEmail(payload = "") {
  const requestOptions = {
    ...customRequestArray("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Authenticate/VerifyEmail`,
    requestOptions
  ).then(handleResponse);
}

function requestPhoneVerification(payload = {}) {
  const requestOptions = {
    ...customRequestArray("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Authenticate/RequestPhoneNumberVerification`,
    requestOptions
  ).then(handleResponse);
}

function verifyPhone(payload = "") {
  const requestOptions = {
    ...customRequestArray("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Authenticate/VerifyPhoneNumber`,
    requestOptions
  ).then(handleResponse);
}

function set2FASetting(payload = {}) {
  const requestOptions = {
    ...customRequestArray("POST", payload.enabled),
  };
  return fetch(`${config.apiUrl}/Authenticate/Set2FA`, requestOptions).then(
    handleResponse
  );
}

function request2FAVerification(payload = {}) {
  const requestOptions = {
    ...customRequestArray("GET")
  };
  const queryString = buildQueryString(payload);
  return fetch(`${config.apiUrl}/Authenticate/Request2FA?${queryString}`, requestOptions).then(
    handleResponse
  );
}

function verify2FA(payload = {}) {
  const requestOptions = {
    ...customRequestArray("POST", payload?.code),
  };
  const queryString = buildQueryString({method: payload?.method});
  return fetch(
    `${config.apiUrl}/Authenticate/Verify2FA?${queryString}`,
    requestOptions
  ).then(handleResponse);
}
