import { vendorService } from "src/services/api";
// import _ from "lodash";

const state = {
  status: {},
  errors: [],
  statusText: "",
  data: [],
  metaData: {},
  dataParam: {
    keyword: "",
    isBusiness: null,
    page: "",
    limit: 50,
  },
  dataOptions: [],
  singleOption: {},
  single: {},
  fetchAllLoading: false,
  fetchSingleLoading: false,
};

const actions = {
  async clearFilter({ commit }) {
    commit("setFilter", {
      keyword: "",
      page: "",
      isBusiness: null,
      isPotential: null,
      limit: 50,
    });
  },

  async getAll({ commit, dispatch }, params = {}) {
    let callRequest = async (params) => {
      commit("setFetchAllLoading", true);
      return await vendorService.getAll(params);
    };
    await callRequest(params).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
        commit("setFetchAllLoading", false);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("setFetchAllLoading", true);
      }
    );
  },

  async filter({ commit, dispatch }, params = {}) {
    commit("setFetchAllLoading", true);
    await vendorService.getAll(params).then(
      (response) => {
        commit("applyFilter", response.data);
        commit("setFetchAllLoading", false);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("setFetchAllLoading", false);
      }
    );
  },

  async search(
    { commit },

    params = { isBusiness: null, keyword: "", page: "", limit: 50 }
  ) {
    commit("setFilter", params);
  },

  async create({ commit, dispatch }, payload) {
    await vendorService.create(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Create successfull",
        };

        commit("createSuccess", formattedResponse);
        commit("appendData", formattedResponse.data);
        dispatch(
          "snackbar/success",
          `Add vendor "${payload.companyName}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("statusError", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch }, payload) {
    await vendorService.update(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Update successfull",
        };

        commit("updateSuccess", formattedResponse);
        commit("updateData", formattedResponse.data);
        dispatch(
          "snackbar/success",
          `Update vendor "${payload.companyName}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("statusError", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async delete({ commit, dispatch }, payload) {
    await vendorService.delete(payload).then(
      (response) => {
        commit("deleteSuccess", response);
        commit("deleteData", response.data);
        dispatch("snackbar/success", `Delete vendor successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getById({ commit }, id) {
    commit("setFetchSingleLoading", true);
    await vendorService.getById(id).then(
      (response) => {
        commit("setSingle", response.data);
        commit("setFetchSingleLoading", false);
        // dispatch(
        //   "snackbar/success",
        //   `Add user group "${payload.name}" successful`,
        //   { root: true }
        // );
      },
      (responseError) => {
        commit("createFailure", responseError);
        commit("setFetchSingleLoading", false);
        // dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getByIdOption({ commit }, id) {
    commit("setFetchSingleLoading", true);
    await vendorService.getById(id).then(
      (response) => {
        commit("setSingleOption", response.data);
        commit("setFetchSingleLoading", false);
        // dispatch(
        //   "snackbar/success",
        //   `Add user group "${payload.name}" successful`,
        //   { root: true }
        // );
      },
      (responseError) => {
        commit("createFailure", responseError);
        commit("setFetchSingleLoading", false);
        // dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setFetchAllLoading(state, statusLoading) {
    state.fetchAllLoading = statusLoading;
  },

  setFetchSingleLoading(state, statusLoading) {
    state.fetchSingleLoading = statusLoading;
  },

  statusOk(state) {
    state.status = { error: false };
    state.statusText = "status-ok";
  },

  statusError(state, error) {
    state.status = { error: true };
    state.statusText = error.responseText;
  },

  setData(state, data) {
    state.data = data;
  },

  setMetaData(state, data) {
    state.metaData = data;
  },

  applyFilter(state, data) {
    state.dataOptions = data;
  },

  setSingleOption(state, data) {
    state.singleOption = data;
  },

  setFilter(state, param) {
    state.dataParam = param;
  },

  setSingle(state, data) {
    state.single = data;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = {
      error: false,
      message: response.message,
    };
    state.single = response.data;
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getFetchIsLoading(state) {
    return state.fetchSingleLoading || state.fetchAllLoading;
  },
  getAll(state) {
    let data = state.data;
    return data;
  },
  getMetaData(state) {
    return state.metaData;
  },
};

export const vendor = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
