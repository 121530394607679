import { paymentHistoryService } from "src/services/api";

const state = {
  status: {},
  errors: [],
  statusOptions: [
    {
      value: "open",
      text: "Open",
    },
    {
      value: "closed",
      text: "Closed",
    },
  ],
  typeOptions: [
    {
      value: "onsite",
      text: "Onsite Support",
    },
    {
      value: "remote",
      text: "Remote Support",
    },
  ],
  statusText: "",
  data: [],
  metaData: {},
  dataParam: {
    status: "",
    keyword: "",
    page: "",
    tags: [],
    limit: 50,
  },
  single: {},

  tableFilter: {
    SearchTerm: null,
    clientId: null,
    status: null,
  },
  loadingTable: false,
};

const defaultFilter = {
  SearchTerm: null,
  clientId: null,
  status: null,
};

const actions = {
  async applyTableFilter({ commit, dispatch }, params) {
    commit("setTableFilter", {
      ...defaultFilter,
      ...params,
    });
    dispatch("getAll", params);
  },

  async clearTableFilter({ commit }) {
    commit("setTableFilter", defaultFilter);
  },

  async clearAll({ commit }) {
    commit("setData", []);
  },

  async resetLoading({ commit }) {
    commit("setLoadingTable", true);
  },

  async clearFilter({ commit }) {
    commit("setTableFilter", {
      ...defaultFilter,
    });
  },

  async getAll({ commit, dispatch }, params) {
    commit("setLoadingTable", true);
    await paymentHistoryService.getAll(params).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
        commit("setLoadingTable", false);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async search({ commit }, params = { keyword: "", page: "", limit: 50 }) {
    commit("setFilter", params);
  },

  async create({ commit, dispatch }, payload) {
    await paymentHistoryService.create(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Create successfull",
        };
        commit("setSingle", response);
        commit("createSuccess", formattedResponse);
        dispatch(
          "snackbar/success",
          `Add payment history "${response.name}" successful`,
          {
            root: true,
          }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch }, payload) {
    await paymentHistoryService.update(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Update successfull",
        };
        commit("updateSuccess", formattedResponse);
        commit("updateData", formattedResponse.data);
        dispatch(
          "snackbar/success",
          `Update payment history "${payload.id}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async delete({ commit, dispatch }, payload) {
    await paymentHistoryService.delete(payload).then(
      (response) => {
        commit("deleteSuccess", response);
        // commit("deleteData", response.data);
        dispatch(
          "snackbar/success",
          `Delete payment history "${payload.id}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getById({ commit }, id) {
    await paymentHistoryService.getById(id).then(
      (response) => {
        commit("setSingle", response);
        // dispatch(
        //   "snackbar/success",
        //   `Add user group "${payload.name}" successful`,
        //   { root: true }
        // );
      },
      (responseError) => {
        commit("createFailure", responseError);
        // dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setTableFilter(state, data) {
    state.tableFilter = data;
  },
  setData(state, data) {
    state.data = data;
  },
  setMetaData(state, data) {
    state.metaData = data;
  },
  setFilter(state, param) {
    state.dataParam = param;
  },

  setSingle(state, data) {
    state.single = data;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = {
      error: false,
      message: response.message,
    };
    state.single = response.data;
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getAll(state) {
    return state.data;
  },
  getMetaData(state) {
    return state.metaData;
  },
  getType: (state) => (value) => {
    return state.typeOptions.find((type) => type.value == value);
  },
};

export const paymentHistory = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
