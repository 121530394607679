import { customRequest, handleResponse, accountId } from "src/helpers";
import { buildQueryString } from "src/utils/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const timeclockService = {
  getAll,
  getById,
  update,
  create,
  entryCreate,
  entryUpdate,
  createFromTask,
  delete: _delete,
  generateReport,
};

function getAll(params = {}) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  let tagsArray = params.tagsArray ? [...params.tagsArray] : [];
  delete params.tagsArray;

  let queryString = buildQueryString(params);

  let tagParam = [];
  if (tagsArray && tagsArray.length > 0) {
    tagsArray.map((tag) => {
      tagParam.push(`tags=${tag}`);
    });
  }

  queryString += `&` + tagParam.join("&");

  let url = `${config.apiUrl}/Tasks?${queryString}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function generateReport(params = {}) {
  const requestOptions = {
    ...customRequest("POST"),
  };

  let { clients, users } = params;

  //bad practice :(
  delete params.clients;
  delete params.users;

  let queryString = buildQueryString(params);

  if (clients && clients.length > 0) {
    clients.map((clientId) => {
      queryString += `&clients=${clientId}`;
    });
  }

  if (users && users.length > 0) {
    users.map((userId) => {
      queryString += `&users=${userId}`;
    });
  }

  let url = `${config.apiUrl}/Reports/timeclock?${queryString}`;

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        Promise.reject("error");
      }
    })
    .then((response) => {
      let dataCsv = response;
      let data = "data:text/csv;charset=utf-8,";
      data += encodeURI(dataCsv);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "timeclock.csv");
      link.click();
    });
}

function getById(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/Tasks/${id}`, requestOptions).then(
    handleResponse
  );
}

function entryCreate(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };

  return fetch(`${config.apiUrl}/TimeClockEntries`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function entryUpdate(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("PUT", payload),
  };

  return fetch(
    `${config.apiUrl}/TimeClockEntries/${payload.id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function create(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };

  return fetch(`${config.apiUrl}/Tasks`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function createFromTask(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };

  return fetch(
    `${config.apiUrl}/Tasks/${payload.id}/CreateTimeClock`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function update(payload) {
  payload.accountId = accountId();
  payload.task["accountId"] = accountId();
  const requestOptions = {
    ...customRequest("PUT", payload),
  };

  return fetch(`${config.apiUrl}/Tasks/${payload.id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function _delete(payload) {
  const requestOptions = {
    ...customRequest("DELETE"),
  };
  return fetch(`${config.apiUrl}/Tasks/${payload.id}`, requestOptions).then(
    handleResponse
  );
}
