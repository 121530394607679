import { companyService } from "src/services/api";

const state = {
  status: {},
  errors: [],
  statusText: "",
  single: {},
  logo: ""
};

const actions = {
  async update({ dispatch, commit }, payload) {
    await companyService.update(payload).then(
      () => {
        commit("setSingle", payload);
      },
      (error) => {
        commit("setFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async updatePayment({ dispatch, commit }, payload) {
    await companyService.updatePayment(payload).then(
      (response) => {
        commit("setSingle", response.data);
      },
      (error) => {
        commit("setFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async getById({ dispatch, commit }, payload) {
    await companyService.getById(payload).then(
      (response) => {
        commit("setSingle", response.data);
      },
      (error) => {
        commit("setFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async getAccount({ dispatch, commit }) {
    await companyService.getAccount().then(
      (response) => {
        commit("setSingle", response.data[0]);
      },
      (error) => {
        commit("setFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async downloadLogo({ dispatch, commit, state }) {
    if(state.single?.logoFileId) {
      try {
        const response = await companyService.downloadLogo(state.single.logoFileId);
        if (response.ok) {
          let blob = await response.blob();
          const reader = new FileReader();
          reader.addEventListener('loadend', () => {
            const dataUrl = reader.result
            commit("setLogo", dataUrl);
          });
          reader.readAsDataURL(blob);
          
        } else if(response.status !== 404) {
          dispatch("snackbar/error", `logo download error`, {
            root: true,
          });
        } 
      } catch (e) {
        dispatch("snackbar/error", `logo download error`, {
          root: true,
        });
      }
    }
  },

  async uploadLogo({ dispatch, commit }, payload) {
    await companyService.uploadLogo(payload).then(
      (result) => {
        commit("setLogoId", result.id);
        commit("statusOk");
        dispatch("snackbar/success", `Company logo changed`, {
          root: true,
        });
      },
      (error) => {
        commit("setFailure", error);
        dispatch("snackbar/error", error, {
          root: true,
        });
      }
    )
  },
};

const mutations = {
  setSingle(state, data) {
    state.single = data;
  },

  setLogoId(state, data) {
    state.single.logoFileId = data;
  },

  setLogo(state, data) {
    state.logo = data;
  },

  statusOk(state) {
    state.statusText = "ok";
    state.status = { error: false, message: "ok" };
  },

  setFailure(state, error) {
    state.status = { error: true, message: error.responseText };
    state.statusText = error.responseText;
  },
};

const getters = {};

export const company = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
