import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./sass/main.scss";

import { createI18n } from "@/i18n/index";
import { VueMaskDirective } from "v-mask";
import moment from "moment";

// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css';

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

if (process.env.VUE_APP_PROD==1) {
  Sentry.init({
    Vue,
    dsn:
      "https://d3cd724b5ae74ffd916b94ab7687ec84@o687845.ingest.sentry.io/5773134",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  console.log('sentry listening', process.env.VUE_APP_ENV);
} else {
  console.log("sentry not listening", process.env.VUE_APP_ENV);
}

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md'
})

Vue.prototype.moment = moment;

const i18n = createI18n(store.state.translation.locale).i18n;
Vue.config.productionTip = false;

Vue.directive("mask", VueMaskDirective);

export const app = new Vue({
         router,
         store,
         vuetify,
         i18n,
         render: (h) => h(App),
       }).$mount("#app");
