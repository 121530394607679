import { customRequest, handleResponse } from "src/helpers";
import { buildQueryString } from "src/utils/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const accountService = {
  getAll,
  update,
  reactivate,
  deactivate,
  delete: _delete,
  getTaxRate,
};

function getAll(payload) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  let queryString = buildQueryString(payload);

  return fetch(`${config.apiUrl}/Accounts?${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function update(payload) {
  const requestOptions = {
    ...customRequest("PUT", payload),
  };
  return fetch(`${config.apiUrl}/Accounts/${payload.id}`, requestOptions).then(
    handleResponse
  );
}

function reactivate(payload) {
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Accounts/${payload.account.id}/Activate`,
    requestOptions
  ).then(handleResponse);
}

function deactivate(payload) {
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Accounts/${payload.account.id}/Deactivate`,
    requestOptions
  ).then(handleResponse);
}

function _delete(payload) {
  const requestOptions = {
    ...customRequest("DELETE", payload),
  };
  return fetch(`${config.apiUrl}/Accounts/${payload.account.id}`, requestOptions).then(
    handleResponse
  );
}

function getTaxRate() {
  const requestOptions = {
    ...customRequest("GET"),
  };
  return fetch(`${config.apiUrl}/Accounts/SalesTaxRate`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}
