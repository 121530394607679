import { accountService } from "src/services/api";

const state = {
  status: {},
  errors: [],
  statusText: "",
  data: [],
  metaData: {},
  totalActiveAccounts: 0,
  totalActiveUsers: 0,
  totalExpectedRevenue: 0,
  dataParam: {
    keyword: "",
    page: "",
    status: "",
    limit: 50,
  },
  dataOptions: [],
  singleOption: {},
  single: {},
  loadingTable: false,
  fetchAllLoading: false,
  fetchSingleLoading: false,
};

const defaultFilter = {
  SearchTerm: null,
  status: null,
};

const actions = {
  async clearFilter({ commit }) {
    commit("setFilter", {
      keyword: "",
      page: "",
      status: "",
      limit: 50,
    });
  },
  async clearTableFilter({ commit }) {
    commit("setTableFilter", {
      ...defaultFilter,
    });
  },
  // async getAll({ commit }, payload = {}) {
  //   commit("setLoadingTable", true);
  //   await accountService.getAll(payload).then(
  //     (response) => {
  //       commit("setData", response);
  //       // commit("setMetaData", response.metaData);
  //       commit("setLoadingTable", false);
  //     },
  //     (error) => commit("getAllFailure", error)
  //   );
  // },
  async getAll({ commit, dispatch }, params = {}) {
    let callRequest = async (params) => {
      commit("setFetchAllLoading", true);
      return await accountService.getAll(params);
    };
    await callRequest(params).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
        commit("setFetchAllLoading", false);
        commit("setTotalActiveAccounts", response.totalActiveAccounts);
        commit("setTotalActiveUsers", response.totalActiveUsers);
        commit("setTotalExpectedRevenue", response.totalExpectedRevenue);

      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("setFetchAllLoading", true);
      }
    );
  },
  async filter({ commit, dispatch }, params = {}) {
    commit("setFetchAllLoading", true);
    await accountService.getAll(params).then(
      (response) => {
        commit("applyFilter", response.data);
        commit("setFetchAllLoading", false);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("setFetchAllLoading", false);
      }
    );
  },

  async search(
    { commit },
    params = { keyword: "", page: "", limit: 50 }
  ) {
    commit("setFilter", params);
  },

  async update({ dispatch, commit }, payload) {
    await accountService.update(payload).then(
      () => {
        commit("statusOk");
      },
      (error) => {
        commit("updateFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async deactivate({ dispatch, commit }, payload) {
    await accountService.deactivate(payload).then(
      () => {
        commit("statusOk");
        dispatch(
          "snackbar/success",
          `Deactivate Account "${payload.account.email}" successful`,
          { root: true }
        );
      },
      (error) => {
        commit("createFailure", error);
        dispatch("alert/error", error.message, { root: true });
      }
    );
  },

  async reactivate({ dispatch, commit }, payload) {
    await accountService.reactivate(payload).then(
      () => {
        commit("statusOk");
        dispatch(
          "snackbar/success",
          `Reactivate Account "${payload.account.email}" successful`,
          { root: true }
        );
      },
      (error) => {
        commit("createFailure", error);
        dispatch("alert/error", error.message, { root: true });
      }
    );
  },

  async delete({ commit, dispatch }, payload) {
    await accountService.delete(payload).then(
      (response) => {
        commit("deleteSuccess", response);
        commit("deleteData", response.data);
        dispatch(
          "snackbar/success",
          `Account deleted successfully`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  updateAccountStatus({ commit }, newStatus) {
    commit("setAccountStatus", newStatus);
  },
  // async delete({ commit }, id) {
  //   commit("deleteRequest", id);
  //   await accountService.delete(id).then(
  //     (account) => commit("deleteSuccess", id, account),
  //     (error) => commit("deleteFailure", { id, error: error.toString() })
  //   );
  // },
  async getById({ commit }, id) {
    commit("setFetchSingleLoading", true);
    await Service.getById(id).then(
      (response) => {
        commit("setSingle", response);
        commit("setFetchSingleLoading", false);
        // dispatch(
        //   "snackbar/success",
        //   `Add user group "${payload.name}" successful`,
        //   { root: true }
        // );
      },
      (responseError) => {
        commit("createFailure", responseError);
        commit("setFetchSingleLoading", false);
        // dispatch("alert/error", responseError, { root: true });
      }
    );
  },
  async getByIdOption({ commit }, id) {
    commit("setFetchSingleLoading", true);
    await accountService.getById(id).then(
      (response) => {
        commit("setSingleOption", response);
        commit("setFetchSingleLoading", false);
        // dispatch(
        //   "snackbar/success",
        //   `Add user group "${payload.name}" successful`,
        //   { root: true }
        // );
      },
      (responseError) => {
        commit("createFailure", responseError);
        commit("setFetchSingleLoading", false);
        // dispatch("alert/error", responseError, { root: true });
      }
    );
  },
  clearData({ commit }) {
    commit("setData", []);
    commit("setMetaData", {});
    commit("setLoadingTable", false);
  },
};

const mutations = {
  setFetchAllLoading(state, statusLoading) {
    state.fetchAllLoading = statusLoading;
  },
  setFetchSingleLoading(state, statusLoading) {
    state.fetchSingleLoading = statusLoading;
  },
  setLoadingTable(state, data) {
    state.loadingTable = data;
  },
  setTableFilter(state, data) {
    state.tableFilter = data;
  },
  setData(state, data) {
    state.data = data;
  },
  setMetaData(state, data) {
    state.metaData = data;
  },
  getAllFailure(state, response) {
    state.statusText = "getall-error";
    state.status = { error: true, message: response.message };
  },
  statusOk(state) {
    state.status = { error: false };
    state.statusText = "status-ok";
  },
  createFailure(state, message) {
    state.status = { error: true, message: message.message };
    state.statusText = "create-error";
  },
  updateFailure(state, response) {
    state.statusText = "update-error";
    state.status = { error: true, message: response.message };
  },
  applyFilter(state, data) {
    state.dataOptions = data;
  },
  setSingleOption(state, data) {
    state.singleOption = data;
  },
  setFilter(state, param) {
    state.dataParam = param;
  },
  setSingle(state, data) {
    state.single = data;
  },
  setTotalActiveAccounts(state, totalActiveAccounts) {
    state.totalActiveAccounts = totalActiveAccounts;
  },
  setTotalActiveUsers(state, totalActiveUsers) {
    state.totalActiveUsers = totalActiveUsers;
  },
  setTotalExpectedRevenue(state, totalExpectedRevenue) {
    state.totalExpectedRevenue = totalExpectedRevenue;
  },
  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item?.accounts?.id), 1);
  },
  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },
  setAccountStatus(state, newStatus) {
    let { data } = state;
    // console.log(state, newStatus);
    data.isDeleted = newStatus;
    state.data = data;
    localStorage.setItem("account", JSON.stringify(data));
  },

  // deleteRequest(state, id) {
  //   // add 'deleting:true' property to account being deleted
  //   state.all.items = state.data.items.map((account) =>
  //     account.id === id ? { ...account, deleting: true } : account
  //   );
  // },
  // deleteSuccess(state, id) {
  //   // remove deleted account from state
  //   state.all.items = state.all.items.filter((account) => account.id !== id);
  // },
  // deleteFailure(state, { id, error }) {
  //   // remove 'deleting:true' property and add 'deleteError:[error]' property to account
  //   state.all.items = state.items.map((account) => {
  //     if (account.id === id) {
  //       // make copy of account without 'deleting:true' property
  //       //                const { deleting, ...accountCopy } = account;
  //       const { ...accountCopy } = account;
  //       // return copy of user with 'deleteError:[error]' property
  //       return { ...accountCopy, deleteError: error };
  //     }

  //     return account;
  //   });
  // },
};

const getters = {
  getFetchIsLoading(state) {
    return state.fetchSingleLoading || state.fetchAllLoading;
  },
  getAll(state) {
    let data = state.data;
    return data;
  },
  getMetaData(state) {
    return state.metaData;
  },

  // getMetaData(state) {
  //   return state.metaData;
  // },
  // getAll(state) {
  //   let data = state.data;
  //   const { SearchTerm } = state.tableFilter;
  //   if (SearchTerm) {
  //     data = _.filter(
  //       data,
  //       _.flow(
  //         _.identity,
  //         _.values,
  //         _.join,
  //         _.toLower,
  //         _.partialRight(_.includes, SearchTerm.toString().toLowerCase())
  //       )
  //     );
  //   }
  //   return data;
  // },

  // getAllFromLatest(state) {
  //   return state.data.reverse();
  // },
};

export const accounts = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
