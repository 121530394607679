import { customRequest, handleResponse, accountId } from "src/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const timeclockEntryService = {
  getById,
  update,
  create,
  delete: _delete,
};

function getById(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  return fetch(`${config.apiUrl}/TimeClockEntries/${id}`, requestOptions).then(
    handleResponse
  );
}

function update(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("PUT", payload),
  };

  return fetch(
    `${config.apiUrl}/TimeClockEntries/${payload.id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function create(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };

  return fetch(`${config.apiUrl}/TimeClockEntries`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function _delete(payload) {
  const requestOptions = {
    ...customRequest("DELETE"),
  };
  return fetch(
    `${config.apiUrl}/TimeClockEntries/${payload.id}`,
    requestOptions
  ).then(handleResponse);
}
