<template>
  <v-app>
    <router-view name="sidebar" />
    <router-view name="header" :handleSettingsDrawer="handleSettingsDrawer" />
    <v-main class="vuse-content">
      <v-snackbar v-model="snackbar.type" :color="snackbar.color"
        >{{ snackbar.message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar.type = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <auto-logout />

      <v-fade-transition>        
        <router-view />
      </v-fade-transition>      
    </v-main>

    <router-view name="footer" />

    <app-settings-drawer
      :handleDrawer="handleSettingsDrawer"
      :drawer="settingsDrawer"
    />
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppSettingsDrawer from "@/layouts/App/AppSettingsDrawer";
import AutoLogout from "newComp/AppSettings/AutoLogout";
export default {
  name: "app",
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      snackbar: (state) => state.snackbar,
    }),
  },
  data: () => {
    return {
      snackbarStatus: false,
      settingsDrawer: false,
    };
  },
  components: {
    AppSettingsDrawer,
    AutoLogout,
  },
  methods: {
    handleSettingsDrawer() {
      this.settingsDrawer = !this.settingsDrawer;
    },
    ...mapActions({
      clearAlert: "alert/clear",
    }),
  },
  //  watch: {
  //      $route (to, from){
  //          // clear alert on location change
  //          this.clearAlert();
  //      }
  //  },
};
</script>
