import { storageFolderService } from "src/services/api";

const state = {
  status: {},
  errors: [],
  statusText: "",
  data: [],
  metaData: {},
  dataParam: {
    status: "",
    keyword: "",
    page: "",
    tags: [],
    limit: 50,
  },
  single: {},

  tableFilter: {
    SearchTerm: null,
    clientId: null,
    tags: null,
    isTimeClockRunning: null,
    hideCompleted: null,
    status: null,
  },
  loadingTable: false,
};

const defaultFilter = {
  SearchTerm: null,
  clientId: null,
  tags: null,
  isTimeClockRunning: null,
  hideCompleted: null,
  status: null,
};

const actions = {
  async applyTableFilter({ commit, dispatch }, params) {
    commit("setTableFilter", {
      ...defaultFilter,
      ...params,
    });
    dispatch("getAll", params);
  },

  async clearTableFilter({ commit }) {
    commit("setTableFilter", {
      SearchTerm: null,
      clientId: null,
      dateStart: null,
      dateEnd: null,
      status: null,
    });
  },

  async clearData({ commit }) {
    commit("setData", []);
  },

  async clearFilter({ commit }) {
    commit("setFilter", {
      keyword: "",
      page: "",
      limit: 50,
    });
  },

  async getAll({ commit, dispatch }, params = {}) {
    commit("setLoadingTable", true);
    await storageFolderService.getAll(params).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
        commit("setLoadingTable", false);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("setLoadingTable", false);
      }
    );
  },

  async search({ commit }, params = { keyword: "", page: "", limit: 50 }) {
    commit("setFilter", params);
  },

  async create({ commit, dispatch }, payload) {
    await storageFolderService.create(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Create successfull",
        };
        commit("setSingle", response);
        commit("createSuccess", formattedResponse);
        dispatch(
          "snackbar/success",
          `Create new folder "${response.name}" successful`,
          {
            root: true,
          }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch }, payload) {
    await storageFolderService.update(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Update successfull",
        };

        commit("updateSuccess", formattedResponse);
        commit("updateData", formattedResponse.data);
        dispatch(
          "snackbar/success",
          `Update folder "${payload.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async delete({ commit, dispatch }, payload) {
    await storageFolderService.delete(payload).then(
      (response) => {
        commit("deleteSuccess", response);
        // commit("deleteData", response.data);
        dispatch(
          "snackbar/success",
          `Delete folder "${payload.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getById({ commit }, id) {
    await storageFolderService.getById(id).then(
      (response) => {
        commit("setSingle", response);
        // dispatch(
        //   "snackbar/success",
        //   `Add user group "${payload.name}" successful`,
        //   { root: true }
        // );
      },
      (responseError) => {
        commit("createFailure", responseError);
        // dispatch("alert/error", responseError, { root: true });
      }
    );
  },
};

const mutations = {
  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setTableFilter(state, data) {
    state.tableFilter = data;
  },

  setData(state, data) {
    state.data = data;
  },

  setMetaData(state, data) {
    state.metaData = data;
  },

  setFilter(state, param) {
    state.dataParam = param;
  },

  setSingle(state, data) {
    state.single = data;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = {
      error: false,
      message: response.message,
    };
    state.single = response.data;
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getNestedIds(state, currentItem) {
    let found = state.data.filter((item) => {
      return item.id == currentItem.parentId;
    });

    if (found && found.length === 1 && found[0].id) {
      let found2 = getters.getNestedIds(state, found[0]);
      return found2 + "|" + currentItem.id;
    }
    return currentItem.id;
  },

  getNestedName(state, currentItem) {
    let found = state.data.filter((item) => {
      return item.id == currentItem.parentId;
    });

    if (found && found.length === 1 && found[0].id) {
      let found2 = getters.getNestedName(state, found[0]);
      return found2 + " / " + currentItem.name;
    }
    return currentItem.name;
  },

  getChildren(state, parentId) {
    return state.data
      .filter((item) => {
        return item.parentId == parentId;
      })
      .map((item) => {
        return {
          ...item,
          pathIds: getters.getNestedIds(state, item),
          pathName: getters.getNestedName(state, item),
          children: getters.getChildren(state, item.id),
        };
      });
  },

  getNestedAll(state) {
    let parents = state.data.filter((item) => {
      return item.parentId == 0;
    });

    return parents.map((item) => {
      return {
        ...item,
        children: getters.getChildren(state, item.id),
      };
    });
  },

  getAll(state) {
    return state.data
      .map((item) => {
        return {
          ...item,
          pathIds: getters
            .getNestedIds(state, item)
            .toString()
            .split("|"),
          pathName: getters.getNestedName(state, item),
        };
      })
      .sort((a, b) => {
        if (a.pathName < b.pathName) {
          return -1;
        }
        if (a.pathName > b.pathName) {
          return 1;
        }
        return 0;
      });
  },
};

export const storageFolder = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
