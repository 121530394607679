import { itemPurchaseService } from "src/services/api";
import _ from "lodash";
const state = {
  status: {},
  errors: [],
  single: {},
  statusText: "",
  data: [],
  metaData: {},
  dataParam: {
    status: "",
    keyword: "",
    page: "",
    tags: [],
    limit: 50,
  },

  tableFilter: {
    SearchTerm: null,
    clientId: null,
    dateStart: null,
    dateEnd: null,
    status: null,
    isInvoiced: null,
  },
  loadingTable: false,
};

const defaultFilter = {
  SearchTerm: null,
  clientId: null,
  dateStart: null,
  dateEnd: null,
  status: null,
  isInvoiced: null,
};

const actions = {
  async applyTableFilter({ commit, dispatch }, params) {
    commit("setTableFilter", {
      ...defaultFilter,
      ...params,
    });
    dispatch("getAll", params);
  },

  async clearTableFilter({ commit }) {
    commit("setTableFilter", {
      ...defaultFilter,
    });
  },

  async getAll({ commit, dispatch }, params = {}) {
    commit("setLoadingTable", true);
    await itemPurchaseService.getAll(params).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
        commit("setLoadingTable", false);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async create({ commit, dispatch }, payload) {
    await itemPurchaseService.create(payload).then(
      (response) => {
        commit("createSuccess", response);
        dispatch("getAll");
        dispatch("snackbar/success", `Add item purchse successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch }, payload) {
    await itemPurchaseService.update(payload).then(
      () => {
        dispatch("getAll");
        dispatch("snackbar/success", `Update item purchase successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async search({ commit }, params = { keyword: "", page: "", limit: 50 }) {
    commit("setFilter", params);
  },

  async delete({ commit, dispatch }, payload) {
    await itemPurchaseService.delete(payload).then(
      () => {
        dispatch("getAll");
        dispatch(
          "snackbar/success",
          `Delete item purchase "${payload.title}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getById({ commit, dispatch }, id) {
    await itemPurchaseService.getById(id).then(
      (response) => {
        commit("setSingle", response);
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setMetaData(state, data) {
    state.metaData = data;
  },

  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setTableFilter(state, data) {
    state.tableFilter = data;
  },

  setData(state, data) {
    state.data = data;
  },

  setSingle(state, data) {
    state.single = data;
  },

  setFilter(state, param) {
    state.dataParam = param;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = { error: false, message: response.message };
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getAll(state) {
    let data = state.data;
    const { SearchTerm } = state.tableFilter;
    if (SearchTerm) {
                      // data = _.filter(data, keyword);
                      data = _.filter(
                        data,
                        _.flow(
                          _.identity,
                          _.values,
                          _.join,
                          _.toLower,
                          _.partialRight(
                            _.includes,
                            SearchTerm.toString().toLowerCase()
                          )
                        )
                      );
                    }
    return data;
  },
};

export const itemPurchase = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
