import { customRequest, handleResponse } from "src/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const clientService = {
  getAll,
  search,
  getById,
  update,
  create,
  delete: _delete,
};

function getAll(params = {}) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  let queryString = new URLSearchParams(params);
  return fetch(`${config.apiUrl}/Clients?${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function search(keyword) {
  const requestOptions = {
    ...customRequest("POST"),
  };
  return fetch(
    `${config.apiUrl}/Clients/search?search=${keyword}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return { data: response };
    });
}

function getById(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/Clients/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function create(payload) {
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(`${config.apiUrl}/Clients`, requestOptions).then(handleResponse);
}

function update(payload) {
  const requestOptions = {
    ...customRequest("PUT", payload),
  };
  return fetch(`${config.apiUrl}/Clients/${payload.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(payload) {
  const requestOptions = {
    ...customRequest("DELETE"),
  };
  return fetch(`${config.apiUrl}/Clients/${payload.id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}
