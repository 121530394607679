import { customRequest, handleResponse } from "src/helpers";
import { buildQueryString } from "src/utils/helpers";
const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const subscriptionService = {
         oneTimePayment,
         cancelSubscription,
         getStatus,
         activateSubscription,
         getAll,
       };

async function oneTimePayment(payload) {
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return await fetch(
    `${config.apiUrl}/Subscription/OneTimePayment`,
    requestOptions
  ).then(handleResponse);
}

async function cancelSubscription(payload) {
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return await fetch(
    `${config.apiUrl}/Subscription/Cancel`,
    requestOptions
  ).then(handleResponse);
}

async function activateSubscription(params) {
  const requestOptions = {
    ...customRequest("POST", {}),
  };
  requestOptions.body = "{}"
  const queryString = buildQueryString(params);
  return await fetch(
    `${config.apiUrl}/Subscription/Activate?${queryString}`,
    requestOptions
  ).then(handleResponse);
}

async function getStatus(payload) {
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return await fetch(
    `${config.apiUrl}/Subscription/Status`,
    requestOptions
  ).then(handleResponse);
}

async function getAll(params) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  let queryString = buildQueryString(params);
  return await fetch(
    `${config.apiUrl}/Subscription/BillingHistory?${queryString}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
