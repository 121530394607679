const state = {
  color: null,
  type: null,
  message: null,
};

const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    success(state, message) {
        state.color = "green";
        state.type = "success";
        state.message = message;
    },
    error(state, message) {
        state.color = "red";
        state.type = "error";
        state.message = message;
    },
    clear(state) {
        state.color = "";
        state.type = null;
        state.message = null;
    }
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};