import { customRequest, handleResponse, accountId } from "src/helpers";
import { buildQueryString } from "src/utils/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const itemService = {
  getAll,
  getById,
  update,
  create,
  delete: _delete,
};

function getAll(params = {}) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  const { tags } = params;
  delete params.tags;
  let queryString = buildQueryString(params);
  if (tags && tags.length > 0) {
    let tagsArray = [];
    tags.map((tag) => {
      tagsArray.push(`tagIds=${tag}`);
    });
    queryString += `&${tagsArray.join(`&`)}`;
  }
  return fetch(`${config.apiUrl}/Items?${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getById(id) {
  const requestOptions = {
    ...customRequest('GET')
  };

  return fetch(`${config.apiUrl}/Items/${id}`, requestOptions).then(
    handleResponse
  );
}

function create(payload) {
  payload.item['accountId'] = accountId();
  const requestOptions = {
    ...customRequest('POST', payload)
  };

  

  return fetch(`${config.apiUrl}/Items`, requestOptions).then(handleResponse);
}

function update(payload) {
  const requestOptions = {
    ...customRequest('PUT', payload)
  };

  return fetch(`${config.apiUrl}/Items/${payload.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(payload) {
  const requestOptions = {
    ...customRequest('DELETE')
  };
  return fetch(`${config.apiUrl}/Items/${payload.id}`, requestOptions).then(
    handleResponse
  );
}
