import { userService } from "src/services/api";
import _ from "lodash";

const state = {
  status: {},
  errors: [],
  statusText: "",
  data: [],
  metaData: {},

  single: {},
  dataParam: {
    keyword: "",
    page: "",
    limit: 50,
  },

  tableFilter: {
    SearchTerm: null,
    isDeleted: null,
  },
};

const defaultFilter = {
  SearchTerm: null,
  isDeleted: null,
};

const actions = {
  async applyTableFilter({ commit, dispatch }, params) {
    commit("setTableFilter", {
      ...defaultFilter,
      ...params,
    });
    dispatch("getAll", params);
  },

  async clearTableFilter({ commit }) {
    commit("setTableFilter", {
      SearchTerm: null,
      isDeleted: null,
    });
  },

  async clearFilter({ commit }) {
    commit("clearFilterField", []);
    commit("setFilter", {
      keyword: "",
      page: "",
      limit: 50,
    });
  },

  async search({ commit }, params = { keyword: "", page: "", limit: 50 }) {
    commit("setFilter", params);
  },

  async update({ dispatch, commit }, payload) {
    await userService.update(payload).then(
      () => {
        // commit("statusOk");
      },
      (error) => {
        commit("createFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async getById({ commit }, id) {
    await userService.getById(id).then(
      (response) => {
        commit("setSingle", response.data);
      },
      (error) => commit("getAllFailure", error)
    );
  },

  async getAll({ commit }, payload = {}) {
    commit("setLoadingTable", true);
    await userService.getAll(payload).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", {
          ...response.metaData,
          numUsers: parseInt(response?.numUsers || '0', 10),
          pricePerUserPerMonth: response?.pricePerUserPerMonth,
          pricePerMonth: parseFloat(response?.pricePerUserPerMonth || '0') * parseInt(response?.numUsers || '0', 10),
        });
        commit("setLoadingTable", false);
      },
      (error) => commit("getAllFailure", error)
    );
  },

  async delete({ commit }, id) {
    commit("deleteRequest", id);
    await userService.delete(id).then(
      (user) => commit("deleteSuccess", id, user),
      (error) => commit("deleteFailure", { id, error: error.toString() })
    );
  },

  async reactivate({ commit, dispatch }, id) {
    await userService.reactivate(id).then(
      () => {
        dispatch("snackbar/success", `Reactivate user successful`, {
          root: true,
        });
      },
      (error) => commit("deleteFailure", { id, error: error.toString() })
    );
  },

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setTableFilter(state, data) {
    state.tableFilter = data;
  },

  setFilter(state, param) {
    state.dataParam = param;
  },
  setData(state, data) {
    state.data = data;
  },
  setMetaData(state, data) {
    state.metaData = data;
  },
  setSingle(state, data) {
    state.single = data;
  },
  getAllRequest(state) {
    state.all = { loading: true };
  },
  getAllSuccess(state, users) {
    state.all = { items: users };
  },
  getAllFailure(state, error) {
    state.all = { error };
  },
  deleteRequest(state, id) {
    // add 'deleting:true' property to user being deleted
    state.all.items = state.all.items.map((user) =>
      user.id === id ? { ...user, deleting: true } : user
    );
  },
  deleteSuccess(state, id) {
    // remove deleted user from state
    state.all.items = state.all.items.filter((user) => user.id !== id);
  },
  deleteFailure(state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    state.all.items = state.items.map((user) => {
      if (user.id === id) {
        // make copy of user without 'deleting:true' property
        //                const { deleting, ...userCopy } = user;
        const { ...userCopy } = user;
        // return copy of user with 'deleteError:[error]' property
        return { ...userCopy, deleteError: error };
      }

      return user;
    });
  },
};

const getters = {
  getMetaData(state) {
    return state.metaData;
  },
  getAll(state) {
    let data = state.data;
    const { SearchTerm } = state.tableFilter;
    if (SearchTerm) {
      data = _.filter(
        data,
        _.flow(
          _.identity,
          _.values,
          _.join,
          _.toLower,
          _.partialRight(_.includes, SearchTerm.toString().toLowerCase())
        )
      );
    }
    return data;
  },

  getAllFromLatest(state) {
    return state.data.reverse();
  },
};

export const user = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
