import { taskService } from "src/services/api";
import _ from "lodash";

const state = {
  status: {},
  errors: [],
  statusOptions: [
    {
      value: "open",
      text: "Open",
    },
    {
      value: "closed",
      text: "Closed",
    },
  ],
  typeOptions: [
    {
      value: "onsite",
      text: "Onsite Support",
    },
    {
      value: "remote",
      text: "Remote Support",
    },
  ],
  statusText: "",
  data: [],
  metaData: {},
  dataParam: {
    status: "",
    keyword: "",
    page: "",
    tags: [],
    limit: 50,
  },
  single: {},
  singleAttachments: [],

  tableFilter: {
    SearchTerm: null,
    clientId: null,
    tags: null,
    isTimeClockRunning: null,
    hideCompleted: null,
    status: null,
  },
  loadingTable: false,
  loadingAction: false,
};

const defaultFilter = {
  SearchTerm: null,
  clientId: null,
  tags: null,
  isTimeClockRunning: null,
  hideCompleted: null,
  status: null,
};

const actions = {
  async applyTableFilter({ commit, dispatch }, params) {
    commit("setTableFilter", {
      ...defaultFilter,
      ...params,
    });
    dispatch("getAll", params);
  },

  async clearTableFilter({ commit }) {
    commit("setTableFilter", {
      SearchTerm: null,
      clientId: null,
      dateStart: null,
      dateEnd: null,
      status: null,
    });
  },

  async clearAll({ commit }) {
    commit("setData", []);
  },

  async clearFilter({ commit }) {
    commit("setFilter", {
      keyword: "",
      page: "",
      limit: 50,
    });
  },

  async clearSingleAttachments({ commit }) {
    commit("setSingleAttachments", []);
  },

  async getAll({ commit, dispatch }, params = {}) {
    commit(
      "setLoadingTable",
      params["noLoading"] ? params["noLoading"] : false
    );
    await taskService.getAll(params).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
        commit("setLoadingTable", false);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async search({ commit }, params = { keyword: "", page: "", limit: 50 }) {
    commit("setFilter", params);
  },

  async create({ commit, dispatch }, payload) {
    await taskService.create(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Create successfull",
        };
        commit("setSingle", response);
        commit("createSuccess", formattedResponse);
        dispatch("snackbar/success", `Add task "${response.name}" successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch }, payload) {
    await taskService.update(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Update successfull",
        };

        commit("updateSuccess", formattedResponse);
        commit("updateData", formattedResponse.data);
        dispatch(
          "snackbar/success",
          `Update task "${response.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async updateStatus({ commit, dispatch }, payload) {
    await taskService.updateStatus(payload).then(
      (response) => {
        let formattedResponse = {
          data: response,
          message: "Update successfull",
        };

        commit("updateSuccess", formattedResponse);
        commit("updateData", formattedResponse.data);
        dispatch(
          "snackbar/success",
          `Update task "${response.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async delete({ commit, dispatch }, payload) {
    await taskService.delete(payload).then(
      (response) => {
        commit("deleteSuccess", response);
        // commit("deleteData", response.data);
        dispatch(
          "snackbar/success",
          `Delete task "${payload.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getById({ commit }, id) {
    await taskService.getById(id).then(
      (response) => {
        commit("setSingle", response);
        // dispatch(
        //   "snackbar/success",
        //   `Add user group "${payload.name}" successful`,
        //   { root: true }
        // );
      },
      (responseError) => {
        commit("createFailure", responseError);
        // dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getAttachments({ commit }, id) {
    commit("setLoadingTable", true);
    await taskService.getByIdFiles(id).then(
      (response) => {
        let { data } = response;
        commit("setSingleAttachments", data);
        commit("setLoadingTable", false);
      },
      (responseError) => {
        commit("createFailure", responseError);
        commit("setLoadingTable", false);
      }
    );
  },

  async addAttachments({ commit, dispatch }, payload) {
    commit("setLoadingAction", true);
    await taskService.addAttachments(payload).then(
      (response) => {
        commit("setLoadingAction", false);
        let formattedResponse = {
          data: response,
          message: "Update successfull",
        };
        commit("updateSuccess", formattedResponse);
        commit("updateData", formattedResponse.data);
        dispatch("snackbar/success", `Associating files to task successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
        commit("setLoadingAction", false);
      }
    );
  },

  async deleteAttachments({ commit, dispatch }, payload) {
    commit("setLoadingAction", true);
    await taskService.deleteAttachments(payload).then(
      () => {
        commit("setLoadingAction", false);
        dispatch(
          "snackbar/success",
          `Delete associated files of task successful`,
          {
            root: true,
          }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
        commit("setLoadingAction", true);
      }
    );
  },

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setLoadingAction(state, data) {
    state.loadingAction = data;
  },


  setSingleAttachments(state, data) {
    let _data = data.map((item) => {
      return {
        ...item,
        isFolder: false,
        icon: "mdi-file",
        parentId: item.parentId ? item.parentId : "",
      };
    });
    state.singleAttachments = _data;
  },  

  setTableFilter(state, data) {
    state.tableFilter = data;
  },

  setData(state, data) {
    state.data = data;
  },

  setMetaData(state, data) {
    state.metaData = data;
  },

  setFilter(state, param) {
    state.dataParam = param;
  },

  setSingle(state, data) {
    state.single = data;
  },


  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = {
      error: false,
      message: response.message,
    };
    state.single = response.data;
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getMetaData(state) {
    return state.metaData;
  },
  getAll(state) {
    let data = state.data;
    const { keyword, status, tags } = state.dataParam;
    if (keyword) {
      // data = _.filter(data, keyword);
      data = _.filter(
        data,
        _.flow(
          _.identity,
          _.values,
          _.join,
          _.toLower,
          _.partialRight(_.includes, keyword.toString().toLowerCase())
        )
      );
    }

    if (status) {
      data = _.filter(data, (item) => {
        return item.status == status;
      });
    }

    if (tags && tags.length > 0) {
      data = _.filter(data, (item) => {
        return item.categories.filter((c) => tags.includes(c.id)).length > 0;
      });
    }
    return data;
  },
  getType: (state) => (value) => {
    return state.typeOptions.find((type) => type.value == value);
  },
};

export const task = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
