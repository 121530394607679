import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehviour(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
});

router.beforeEach((to, from, next) => {

  // Clear any alert before route changed
  store.dispatch("alert/clear");
  
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/signup", "/forgot-password", "/lock-screen"];

  const publicDynamicPages = ["/payment/.*"];

  const isPublicPage =
    publicDynamicPages.filter((rule) => to.path.toString().match(rule)).length >
    0
      ? true
      : false;

  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  const loggedInId = JSON.parse(loggedIn)?.user?.accountId || 0;

  if (authRequired && !isPublicPage && !loggedIn) {
    return next("/login");
  }

  // hardcode it so only sheng's account can access internal payment page
  const shengOnlyPages = ["/pay"];
  const loggedInSheng = JSON.parse(loggedIn)?.user?.accountId == 27;
  const shengRequired = shengOnlyPages.includes(to.path);
  if (shengRequired && !loggedInSheng) {
    return next("/login");
  }

  // hardcode it so only girliegarage's account can access GG invoice pages
  const ggOnlyPages = ["/gginvoices", "/gginvoices/create"];
  const loggedInGG = [67, 68].includes(loggedInId); // TODO: store in env
  const ggRequired = ggOnlyPages.includes(to.path) || ggOnlyPages.some(x => to.path.startsWith(x + '/'));
  if (ggRequired && !loggedInGG) {
    return next("/login");
  }

  router.afterEach(() => {
    window.scrollTo(0, 0);
  });
  next();
});

export { router };
