import {
  customRequest,
  customRequestArray,
  handleResponse,
  accountId,
} from "src/helpers";
import { buildQueryString } from "src/utils/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const vaultService = {
  getAll,
  getById,
  update,
  create,
  delete: _delete,
  updateStatus,
  sendEmail,
};

function getAll(params = {}) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  let tagsArray = params.tagsArray ? [...params.tagsArray] : [];
  delete params.tagsArray;

  let queryString = buildQueryString(params);

  let tagParam = [];
  if (tagsArray && tagsArray.length > 0) {
    tagsArray.map((tag) => {
      tagParam.push(`tags=${tag}`);
    });
  }

  queryString += `&` + tagParam.join("&");

  let url = `${config.apiUrl}/Vault?${queryString}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getById(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/Vault/${id}`, requestOptions).then(
    handleResponse
  );
}

function create(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };

  return fetch(`${config.apiUrl}/Vault`, requestOptions).then(handleResponse);
}

function update(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("PUT", payload),
  };

  return fetch(`${config.apiUrl}/Vault/${payload.id}`, requestOptions).then(
    handleResponse
  );
}

function updateStatus(payload) {
  const requestOptions = {
    ...customRequestArray("PUT", payload.status),
  };

  return fetch(
    `${config.apiUrl}/Vault/${payload.id}/status`,
    requestOptions
  ).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(payload) {
  const requestOptions = {
    ...customRequest("DELETE"),
  };
  return fetch(`${config.apiUrl}/Vault/${payload.id}`, requestOptions).then(
    handleResponse
  );
}

function sendEmail(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(
    `${config.apiUrl}/Vault/${payload.id}/email`,
    requestOptions
  ).then(handleResponse);
}
