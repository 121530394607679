import { customRequest, handleResponse } from "src/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const userGroupService = {
  getAll,
  getById,
  update,
  create,
  delete: _delete,
  changeAccess,
};


function getAll() {
  const requestOptions = {
    ...customRequest("GET"),
  };
  return fetch(
    `${config.apiUrl}/UserGroupPermissions/All`,
    requestOptions
  ).then(handleResponse).then((response) => {
    return {
      'data': response
    }
  });
}

function getById(id, token) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  if (token) {
    requestOptions['headers']["Authorization"] = "Bearer " + token;
  }

  return fetch(`${config.apiUrl}/UserGroups/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function create(payload) {
  const requestOptions = {
    ...customRequest("POST", payload),
  };

  return fetch(`${config.apiUrl}/UserGroups`, requestOptions).then(
    handleResponse
  ).then(response => {
    return {
      'data': {
        id: payload.id,
        ...response
      }
    }
  });
}

function update(payload) {
  const requestOptions = {
    ...customRequest("PUT", payload),
  };

  return fetch(`${config.apiUrl}/UserGroups/${payload.id}`, requestOptions).then(
    handleResponse
  ).then(response => {
    return {
      'data': {
        id: payload.id,
        ...response
      }
    }
  });
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(payload) {
  const requestOptions = {
    ...customRequest("DELETE", payload),
  };
  return fetch(`${config.apiUrl}/UserGroups/${payload.id}`, requestOptions).then(
    handleResponse
  ).then(response => {
    return {
      'data': {
        id: payload.id,
        ...response
      }
    }
  });
}

function changeAccess(payload) {
  const requestOptions = {
    ...customRequest("PUT", payload),
  };

  return fetch(
    `${config.apiUrl}/UserGroupPermissions/UserGroup/${payload.groupId}/Feature/${payload.featureId}/Action/${payload.actionId}`,
    requestOptions
  ).then(handleResponse);
}
