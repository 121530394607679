import { userGroupService } from "src/services/api";

const state = {
  status: {},
  errors: [],
  statusText: "",
  data: [],
  single: {},
};

const actions = {
  async getAll({ commit, dispatch }) {
    await userGroupService.getAll().then(
      (response) => {
        commit("setData", response.data);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async changeAccess({ dispatch }, payload) {
    await userGroupService.changeAccess(payload).then(
      (response) => {
        // dispatch("getAll");
        console.log(response);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async create({ commit, dispatch }, payload) {
    await userGroupService.create(payload).then(
      (response) => {
        commit("createSuccess", response);
        commit("appendData", response.data);
        dispatch("getAll");
        dispatch(
          "snackbar/success",
          `Add user group "${payload.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch }, payload) {
    await userGroupService.update(payload).then(
      (response) => {
        commit("updateSuccess", response);
        commit("updateData", response.data);
        dispatch("getAll");
        dispatch(
          "snackbar/success",
          `Update user group "${payload.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async delete({ commit, dispatch }, payload) {
    await userGroupService.delete(payload).then(
      (response) => {
        commit("deleteSuccess", response);
        commit("deleteData", response.data);
        dispatch("getAll");
        dispatch(
          "snackbar/success",
          `Delete user group "${payload.name}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getById({ commit, dispatch }, id) {
    await userGroupService.getById(id).then(
      (response) => {
        commit("setSingle", response.data);
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },
};

const mutations = {
  setSingle(state, data) {
    state.single = data;
  },

  setData(state, data) {
    state.data = data;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = { error: false, message: response.message };
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getAllFromLatest(state) {
    return state.data.reverse();
  },
};

export const userGroup = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
