import {
  customRequest,
  customRequestArray,
  customRequestUpload,
  handleResponse,
  accountId,
} from "src/helpers";
import { buildQueryString } from "src/utils/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL + `/BlobFiles`,
};

export const storageService = {
  getAll,
  getById,
  update,
  upload,
  downloadVault,
  uploadVault,
  deleteVault,
  download,
  create,
  delete: _delete,
  updateStatus,
  listAll,
};

function listAll(params = {}) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  let tagsArray = params.tagsArray ? [...params.tagsArray] : [];
  delete params.tagsArray;

  let queryString = buildQueryString(params);

  let tagParam = [];
  if (tagsArray && tagsArray.length > 0) {
    tagsArray.map((tag) => {
      tagParam.push(`tags=${tag}`);
    });
  }

  queryString += `&` + tagParam.join("&");

  let url = `${config.apiUrl}/folder?${queryString}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getAll(params = {}) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  let tagsArray = params.tagsArray ? [...params.tagsArray] : [];
  delete params.tagsArray;

  let queryString = buildQueryString(params);

  let tagParam = [];
  if (tagsArray && tagsArray.length > 0) {
    tagsArray.map((tag) => {
      tagParam.push(`tags=${tag}`);
    });
  }

  queryString += `&` + tagParam.join("&");

  let url = `${config.apiUrl}?${queryString}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getById(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/${id}`, requestOptions).then(handleResponse);
}

function create(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("POST", payload),
  };
  return fetch(`${config.apiUrl}/upload`, requestOptions).then(handleResponse);
}

function upload(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequestUpload("POST", payload),
  };
  return fetch(`${config.apiUrl}/upload`, requestOptions).then(handleResponse);
}

function downloadVault(params) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  const queryString = buildQueryString({vaultId: params.vaultId});
  const url = `${config.apiUrl}/${params.id}/download?${queryString}`;

  return fetch(url, requestOptions);
}

function uploadVault(payload) {
  const user = JSON.parse(localStorage.getItem("user"));

  const formData  = new FormData();
  formData.append("File", payload.file, payload.filename);
  formData.append("VaultId", payload.vaultId);

  const url = `${config.apiUrl}/upload/vault`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": "Bearer " + user.token,
      Charset: "utf-8"
    },
    body: formData
  };
  return fetch(url, requestOptions)
    .then(r => r.json()) 
    .then(data => {
      return data;
    })
}

function deleteVault(params) {
  const requestOptions = {
    ...customRequest("DELETE"),
  };

  const queryString = buildQueryString({vaultId: params.vaultId});
  const url = `${config.apiUrl}/${params.id}?${queryString}`;

  return fetch(url, requestOptions);
}

function download(payload) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  return fetch(`${config.apiUrl}/${payload.id}/download`, requestOptions);
}

function update(payload) {
  payload.accountId = accountId();
  const requestOptions = {
    ...customRequest("PUT", payload),
  };

  return fetch(`${config.apiUrl}/${payload.id}`, requestOptions).then(
    handleResponse
  );
}

function updateStatus(payload) {
  const requestOptions = {
    ...customRequestArray("PUT", payload.status),
  };

  return fetch(`${config.apiUrl}/${payload.id}/status`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(payload) {
  const requestOptions = {
    ...customRequest("DELETE"),
  };
  return fetch(`${config.apiUrl}/${payload.id}`, requestOptions).then(
    handleResponse
  );
}
